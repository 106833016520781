import './index.scss';
import "babylonjs-loaders";
import "react-bootstrap";
import moment from 'moment';
import MobileDetect from 'mobile-detect';
import { createFlipper, Flipper  } from './flipper';
import { AbstractMesh, AmmoJSPlugin, Color3, Color4, CubeTexture, CustomOptimization, DynamicTexture, Engine, FlyCamera, HemisphericLight, HighlightLayer, Logger, Matrix, Mesh, MeshBuilder, MultiMaterial, PhysicsImpostor, PointLight, PostProcessesOptimization, Quaternion, Scene, SceneLoader, SceneOptimizer, SceneOptimizerOptions, ShadowGenerator, Sound, StandardMaterial, SubMesh, Texture, TextureOptimization, TransformNode, Vector3, Vector4, Animation, PhysicsViewer } from 'babylonjs';

export const CCD = true;
export const FLIPPER_FRICTION = 0.9;


let innerGame:any = null;
window.addEventListener('initGame', async (ev) => {
	// @ts-ignore
	//await Ammo();
	if(!innerGame) {
		innerGame = new Game(ev);
	} 
});
window.addEventListener('endGame', (ev) => {
	if(innerGame) {
		innerGame.destroyGame();
		innerGame = null;
	} 
});
window.addEventListener('getGameScore', (event:any) => {
	if(innerGame) {
		event.detail.callback(innerGame.score);
	} else {
		event.detail.callback(0);
	}
});

class Game {	
	scene:Scene;
	SHOW_PHYSICS_MODELS = false;
	SHOW_OBSTACLES_MODELS = false;
	SHOW_PHYSICS_IMPOSTORS = false;
	PHYSICS_MODELS_VISIBILITY = this.SHOW_PHYSICS_MODELS ? 0.5 : 0;
	OBSTACLES_MODELS_VISIBILITY = this.SHOW_PHYSICS_MODELS || this.SHOW_OBSTACLES_MODELS ? 0.5 : 0;
	GRAPHIC_MODELS_VISIBILITY =  1;
	CONSOLE_DEBUGGING = true;
	SHOW_POPUPS = true;
	SHOW_FPS = false;
		
	// increasing this will make the simulation better, but require more CPU
	SUBSTEPS_INIT = 2;
	SUBSTEPS_MAX = 4;
	TESTBED = false;
	DEBUG_KEYS = true;
	CAM_POS: number = this.TESTBED ? 2 : 0; // 0 = normal, 1 = table overview, 2 = testbed, 3 = zoom out, 4 = flippers, 5 = mobile 1, 6= mobile 2, 7 = free
	FLIPPER_AUTOFIRE = false;
	RAIN_O_BALLS = false;
	SHOW_AXES = this.CAM_POS > 0;
	SHADOWS = false;
	
	FLOOR_FRICTION = 0.9;
	WALL_FRICTION = 0.9;
	BALL_FRICTION = 0.84;
	BALL_RESTITUTION = 0.01;
	BOUNDS_RESTITUTION = 1;
	OBSTACLE_RESTITUTION = 140;
	GOALIE_RESTITUTION = 300;

	OBSTACLE_FRICTION= 0.9;
	GRAVITY = 9.8 * 100;
	BALL_START_POSITION = new Vector3(-31.5, 1.35, 45);
	WORLD_ORIGIN = new Vector3(0, 0, 0);
	FIELD_CENTER = new Vector3(0, 10, 4);
	md = new MobileDetect(window.navigator.userAgent);
	goalies = ['winter-Pinball-goalkeeper.png']
	
	PORTRAIT_CAMERA_TARGET = new Vector3(0, -8, -13);
	TALL_PORTRAIT_CAMERA_TARGET = new Vector3(0, -5, -10);
	
	cameraModifierZ = 107; //85;
	cameraModifierY = 48; // 65;

	MAX_BALLS = 8;
	START_BALLS = 3;
	
	BOTTOM_POS_Z = 56;
	SKIP_INTRO_ANIM = false;
	CLEAR_COLOR = new Color4(17/255,21/255,58/255, 1);
	introAnimationDone = false;
	
	startLoad:number = null;
	
	score:number = 0;
	highscoreToBeat:number = null;
	
	casePhysics:AbstractMesh;
	walls:AbstractMesh;
	backwall:AbstractMesh;
	tabletop:AbstractMesh;
	innerwallleft:AbstractMesh;
	
	goal:TransformNode;
	goalmeshes:any = null;
	
	arrowIsDown = false;
	canvas:HTMLCanvasElement = document.getElementById("renderCanvas") as HTMLCanvasElement;
	CANVAS_SCALE_STEPS = [1, 0.9, 0.8, 0.7];
	
	canvasScale: number = 0;
	scoreElement = document.getElementById("gamescore");
	gameContainer = document.getElementById("game-main");
	overlay = document.getElementById("game-overlay");
	loadingScreen = document.getElementById("loading-wrap");
	loadingContent = document.getElementById("loading-content");
	soundToggle = document.getElementById('soundToggle');
	infiniteToggle = document.getElementById('infiniteToggle');
	cameraToggle = document.getElementById('cameraToggle');
	cameraInputs = document.getElementById('cameraInputs');
	cameraPositionX = document.getElementById('cameraPositionX');
	cameraPositionY = document.getElementById('cameraPositionY');
	cameraPositionZ = document.getElementById('cameraPositionZ');
	targetPositionX = document.getElementById('targetPositionX');
	targetPositionY = document.getElementById('targetPositionY');
	targetPositionZ = document.getElementById('targetPositionZ');
	cameraPositionXi = document.getElementById('cameraPositionXi');
	cameraPositionYi = document.getElementById('cameraPositionYi');
	cameraPositionZi = document.getElementById('cameraPositionZi');
	targetPositionXi = document.getElementById('targetPositionXi');
	targetPositionYi = document.getElementById('targetPositionYi')
	targetPositionZi = document.getElementById('targetPositionZi')
	resetCameraButton = document.getElementById('resetCamera');
	
	bounds = this.gameContainer.getBoundingClientRect()
	
	engine = new Engine(this.canvas, !this.isSafari15_4(), {
		premultipliedAlpha: false,
		depth: true,
		preserveDrawingBuffer: !this.isMobileDevice(),
		stencil: true
	}, false);
	
	lastScore = 0;
	scoreTimeout = 3000;
	playInfinite = false;
	gamePaused = true;

	// highlightColor:Color3 = new Color3(0.968627, 0.650980, 0.000000); // yellow
	highlightColor:Color3 = new Color3(0.792156, 0.180392, 0.333333); // #CA2E55

	chevronLightColor:Color3 = new Color3(1, 1, 1);
	chevronLightOffColor:Color3 = new Color3(0, 0, 0);
	
	machineAngle = Math.PI*0.03;
	yAxis = new Vector3(0,1,0);
	xAxis = new Vector3(1,0,0);
	zAxis = new Vector3(0,0,1);
	pinBallDiameter = 2.7;
	plungerHeight = 2.9;
	plungerMax = { x: -30.9, y: 1.5, z: 56 };
	plungerMin = { x: -30.9, y: 1.5, z: 61 };
	plungerMoveDownStep = 30;
	plungerMovedDown = false;
	plungerIsFiring = false;
	plungerFiredBall = false;
	plungerPinballDistance = 9;
	plungerIsForce = 100;
	plungerFireForceMultiplier = 260;
	plungerFireSteps = 0;
	plungerFireStepSize = 0.5;
	zeroVelocity = new Vector3(0,0,0);
	cubeUVFrontFaceOnly = [new Vector4(0, 0, 0, 0), new Vector4(0, 0, 1, 1), new Vector4(0, 0, 0, 0), new Vector4(0, 0, 0, 0), new Vector4(0, 0, 0, 0), new Vector4(0, 0, 0, 0)]
	cubeUVFrontCorner = [new Vector4(0, 0, 0, 0),  new Vector4(1/2, 0, 1, 1), new Vector4(0, 0, 0, 0),new Vector4(0/2, 0, 0.5, 1), new Vector4(0, 0, 0, 0), new Vector4(0, 0, 0, 0)]
	shadows: ShadowGenerator | null = null;
	shadowMeshes:any[] = [];
	meshesToUnIndex:any[] = [];
	meshesToFreezeWorldMatrix:any[] = [];
	currentSubStep = this.SUBSTEPS_INIT;
	leftFlipper:Flipper;
	rightFlipper:Flipper;
	leftIsPowered:boolean = false;
	rightIsPowered:boolean = false;
	
	goalieObstacle:Mesh;
	
	kado:AbstractMesh;
	kado_position = new Vector3(-13, 0, -6);
	sled:AbstractMesh;
	sled_position =  new Vector3(15, 0, 28);
	
	tree:AbstractMesh;
	tree_position = new Vector3(15, 0, -10);
	snowman:AbstractMesh;
	snowman_position = new Vector3(-15, 0, 28);
	snowmanShadow:Mesh;


	// shoes:AbstractMesh;
	shoes:TransformNode;
	shoes_position = new Vector3(-15, 1.9, 28);
	shoesDummy:Mesh;

	
	goalObstacle:Mesh;
	goaliePlane:Mesh;
	goalieTexture:Texture;
	goalieMaterial:StandardMaterial;
	ballsInReserve = 0;
	defaultBackboardImage:any = null;
	backboardFiles:{src:string, fromDate?:string, toDate?:string}[] = [
		{
			src: "./assets/3d-models/Images/backboard-01.jpg?nocache=" + moment().valueOf()
		},
		{
			src: "./assets/3d-models/Images/backboard-02.jpg?nocache=" + moment().valueOf(),
			fromDate:"2022-12-16",
			toDate: "2022-12-25"
		},
		{
			src: "./assets/3d-models/Images/backboard-03.jpg?nocache=" + moment().valueOf()
		}
	];
	backboardImages:any[] = [];
	goalImageShowing:boolean = false;
	currentBackboardImageIndex:number = 0;
	goalBackboardImage:any = null;
	currentBackboardImage:any = null;
	soundOnImage:any = null;
	soundOffImage:any = null;
	currentSmallButtonImage:any = null;
	backboardTexture:DynamicTexture;
	backboardMaterial:StandardMaterial;
	backboardContext:any;
	// buttonShineTexture:Texture;
	leftButtonShineMaterial:StandardMaterial;
	
	goalieObstaclePlanePosition:Vector3 = new Vector3(0.25,1.5,0);
	mobileGoalieObstaclePlanePosition:Vector3 = new Vector3(0.25,10,2.5);

	fakeShadows:Map<string, Mesh[]> = new Map<string, Mesh[]>();
	
	prevRenderWidth = 0;
	prevRenderHeight = 0;	
	
	releasePlungerSound:Sound
	goalSound:Sound
	gameStart:Sound
	flipperSound:Sound
	chipsSound:Sound
	rouletteSound:Sound
	slotsSound:Sound
	cardsSound:Sound
	loseSound:Sound
	// backgroundMusic:Sound
	playingMucic:Sound
	backgroundMusic:Sound[] = [];
	currentBgMusic:number = 0;
	//backgroundMusic2:Sound
	minfps = 60;
	minshadowfps = 50;
	
	maxShadowResolution = 1024;
	minShadowResolution = 256;
	currentShadowResolution = this.maxShadowResolution;
	maxBlur = 6;
	currentBlur = 2;
	minBlur = 2;
	shadowResolutionOptimisation = new CustomOptimization(1);
	
	// resolution will scale when going under the MIN or over the GOOD for a while
	GOOD_FPS = 40;
	MIN_FPS = 25;
	
	optimizerOptions = new SceneOptimizerOptions(this.minfps, 1000);
	optimizer:SceneOptimizer	

	physicsViewer:PhysicsViewer;
	gravityVector = new Vector3(0,-this.GRAVITY, 0);
	physicsEngine:any = new AmmoJSPlugin();
	
	pinballMachine:TransformNode
	environmentLight:HemisphericLight
	caseLight1:PointLight
	caseLight2:PointLight
	
	floor:Mesh;
	ceiling:Mesh;
	frontwall:Mesh;
	plunger:AbstractMesh;
	leftButton:TransformNode;
	leftButtonMeshes:Array<AbstractMesh>;
	smallButton:TransformNode;
	smallButtonMeshes:Array<AbstractMesh>;
	rightButton:TransformNode;
	rightButtonMeshes:Array<AbstractMesh>;
	grassTexture:Texture;
	grassMaterial:StandardMaterial;
	fieldLinesTexture:Texture;
	fieldLinesMaterial:StandardMaterial;
	pinBall:Mesh;
	ballTexture:Texture;
	ballMaterial:StandardMaterial;
	skybox:Mesh;
	skyboxMaterial:StandardMaterial;
	skyboxTexture:CubeTexture;
	
	chevronLight:AbstractMesh;
	chevronLights:AbstractMesh[] = [];
	chevronTriggers:Vector3[] = [];
	amountOfLights = 6;
	
	camera:FlyCamera
	currentCameraPreset = this.isPhoneDevice() ? 9 : 0;
	highlightLayer:HighlightLayer
	lastSubloopRun = 0;
	lastSubloopRender = 0;
	
	ballUi = $('#balls-left-ui');
	ballCount = $('#ball-count');
	
	ledboardTexture:Texture = null;
	ledboardMaterial:StandardMaterial = null;
	goalNetTexture:Texture = null;
	goalNetMaterial:StandardMaterial = null;
	fpsLabel = document.getElementById("fpsLabel");

	squareShadowTexture:Texture;
	roundShadowTexture:Texture;
	
	//
	setposCount = 40;
	goalieX = 0;
	goalieMax = 6;
	goalieMin = -6;
	goalieGoingRight = true;
	renderTimePassed:number = 0;
	
	collisionObjects:Array<any> = null;
	
	goalieMovement = 20;		
	autoFireId:any = null;
	autoFirePause = 100;
	checksBeforeReady = 0;

	scoreResetEvent = new CustomEvent('scoreReset');
	lastRenderRun = 0;
		lastActualRender = 0;
		soccerBall:TransformNode
		i = 0;
		
		backboardOpacity = 0;
		backboardAnimating = false;
		
		leftUpId:any = [];
		rightUpId:any = [];
		draggingId:any = null;
		draggingPlunger = false;
		dragDistance = 0;
		dragStart = 0;		
		
		quicklaunch = false;
		quicklaunchTime = 0;		
		nextShine = 0;	
		
		cameraControlsActive = false;	
		curSubSteps: number;
		win:any = this.CONSOLE_DEBUGGING ? window : null;
		onRenderList: any[] = [];
		sounds:Sound[] = [];
		trackToken:string;
		ref:string;
		playerData:any = {
			email: '',
			code: ''
		};
		redirectUrl:string = 'https://www.smartvertizing.nl';
		apiUrl:string = 'https://manager.spinthewheel.nl/api';
		userAgent:string = '';
		gameId = -1;
		checked_bonus_balls = false;
		bonus_lives = 0;
		bonus_type_id = 0;
	
	constructor(event:any) {		
		Logger.LogLevels = Logger.NoneLogLevel
		this.getConfig();

		this.trackToken = event.detail.trackToken;
		this.ref = event.detail.ref;
		this.playerData = event.detail.playerData;
		this.ballsInReserve = typeof event.detail.spinsLeft == "string" ? parseFloat(event.detail.spinsLeft) : event.detail.spinsLeft;
		this.userAgent = event.detail.userAgent;
		this.bonus_lives = typeof event.detail.bonus_lives == "string" ? parseFloat(event.detail.bonus_lives) : event.detail.bonus_lives;
		this.bonus_type_id = typeof event.detail.bonus_type_id == "string" ? parseFloat(event.detail.bonus_type_id) : event.detail.bonus_type_id;
		this.updateBallUi();

		if(this.CONSOLE_DEBUGGING) {
			this.win.cameraModifierZ = this.cameraModifierZ;
			this.win.cameraModifierY = this.cameraModifierY;
			this.win.setPresetCameraPos = (preset:number) => this.setPresetCameraPos(preset);
			
			this.win.PORTRAIT_CAMERA_TARGET = this.PORTRAIT_CAMERA_TARGET;
			this.win.TALL_PORTRAIT_CAMERA_TARGET = this.TALL_PORTRAIT_CAMERA_TARGET;
		}
		
		
		this.canvas.width = window.innerWidth; // TODO: check for mobile!
		this.canvas.height = window.innerHeight; // TODO: check for mobile!
		
		
		// Create the scene space
		this.scene = new Scene(this.engine, {
			useMaterialMeshMap: true,
			useGeometryUniqueIdsMap: true,
		});
		this.scene.clearColor = this.CLEAR_COLOR;
		this.scene.autoClear = false;
		this.scene.collisionsEnabled = false;		
		
		this.scene.blockMaterialDirtyMechanism = true;
		this.scene.audioEnabled = false;

		if(this.SHOW_PHYSICS_IMPOSTORS) {
			this.physicsViewer = new PhysicsViewer(this.scene);
		}
		
		this.releasePlungerSound = new Sound('releasePlungerSound', './assets/sounds/plunger.mp3', this.scene);
		this.goalSound = new Sound('goalSound', './assets/sounds/goal-score.mp3', this.scene);
		//this.gameStart = new Sound('gameStart', './assets/sounds/aanvallen.mp3', this.scene);
		this.gameStart = new Sound('gameStart', './assets/sounds/referee_whistle.mp3', this.scene);
		this.flipperSound = new Sound('flipperSound', './assets/sounds/flippers.mp3', this.scene, null, {loop:false, autoplay:false});
		this.chipsSound = new Sound('chipsSound', './assets/sounds/point-score.mp3', this.scene);
		this.rouletteSound = new Sound('rouletteSound', './assets/sounds/point-score.mp3', this.scene);
		this.slotsSound = new Sound('slotsSound', './assets/sounds/point-score.mp3', this.scene);
		this.cardsSound = new Sound('cardsSound', './assets/sounds/point-score.mp3', this.scene);
		this.loseSound = new Sound('loseSound', './assets/sounds/fail-ball.mp3', this.scene);
		// this.backgroundMusic = new Sound('backgroundMusic', './assets/sounds/christmas-background-short.mp3', this.scene, null, {loop:true, autoplay:false});
		
		let musicFiles = [
			'bg-music-1',
			'bg-music-2',
			'bg-music-3'
		];
		for(let music of musicFiles) {
			const mucic:Sound = new Sound(music, `./assets/sounds/${music}.mp3`, this.scene, null, {volume: 0.5});
			mucic.onended = ()=> { this.onMusicEnded(); }
			this.backgroundMusic.push(mucic);
		}
		// set current music to start
		this.currentBgMusic = Math.floor(Math.random()*this.backgroundMusic.length-1);

		this.sounds = [
			this.releasePlungerSound,
			this.goalSound,
			this.gameStart,
			this.flipperSound,
			this.chipsSound,
			this.rouletteSound,
			this.slotsSound,
			this.cardsSound,
			this.loseSound
		]
		this.sounds = this.sounds.concat(this.backgroundMusic);
		
		this.shadowResolutionOptimisation.onApply = (scene, optimizer) => this.optimizeShadowResolution(scene, optimizer);
		
		this.optimizerOptions.addOptimization(this.shadowResolutionOptimisation);
		this.optimizerOptions.addOptimization(new TextureOptimization(0, 512));
		this.optimizerOptions.addOptimization(new PostProcessesOptimization(0));
		
		this.optimizer = new SceneOptimizer(this.scene, this.optimizerOptions);
		this.scene.enablePhysics(this.gravityVector, this.physicsEngine);
		(this.scene as any).workerCollisions = true; // somehow typescript doesn't recognize this
		this.physicsEngine.setTimeStep(1/120);
		//
		// // this function can dynamically change the substeps,
		// // but that will cause the ball to behave rather differently,
		// // so unfortunately we can't actually use it
		this.setSubSteps(this.SUBSTEPS_INIT);		
		
		this.pinballMachine = new TransformNode("pinballMachine", this.scene);		
	
		this.camera = new FlyCamera("Camera", new Vector3(40,60,78+20), this.scene);
		
		this.scene.setActiveCameraByName("Camera");
		
		// Add the highlight layer.
		this.highlightLayer = new HighlightLayer("highlightLayer", this.scene);
		
		
		
		this.soundToggle.addEventListener('click', (ev) => this.onToggleSound(ev));
		
		window.addEventListener('spinsLeftChanged', (event:any) => {
			this.ballsInReserve = typeof event.detail.spinsLeft == "string" ? parseFloat(event.detail.spinsLeft) : event.detail.spinsLeft;
		});
		window.addEventListener('updateHighScores', (event:any) => {
			this.updateHighScore(event.detail.highscores);
		});

		
		this.scene.onAfterPhysicsObservable.add(() => {
			this.runRenderList();
		});
		this.scene.onBeforePhysicsObservable.add(() => {
			this.checkObstacleCollisions();
		});	
		
		this.soccerBall = new TransformNode("soccerBall", this.scene);		
		
		
		// window.addEventListener('startGame', startGame);
		this.startLoad = moment().valueOf();
		this.updateSceneSize();
		
		this.buildScene(() => {	
			if(this.collisionObjects == null) {
				this.collisionObjects = [
					{ name:	'shoes', lastTrigger:0, cooldown: 1000, isGoal:false, points: 10, object: this.snowman, func: (object:any)  => { this.shakeAnimation(this.snowman); for(let mesh of this.snowman.getChildMeshes()) {this.highLightMesh(mesh);} }, sound: this.rouletteSound },
					{ name:	'sled', lastTrigger:0, cooldown: 1000, isGoal:false, points: 10, object: this.sled, func: (object:any) => { this.shakeAnimation(this.sled); this.highLightMesh(this.sled); }, sound: this.cardsSound },
					{ name:	'tree', lastTrigger:0, cooldown: 1000, isGoal:false, points: 20, object: this.tree, func: (object:any)  => { this.shakeAnimation(this.tree); this.highLightMesh(this.tree); for(let mesh of this.tree.getChildMeshes()) {this.highLightMesh(mesh);}}, sound: this.rouletteSound },
					{ name:	'kado', lastTrigger:0, cooldown: 1000, isGoal:false, points: 20, object: this.kado, func: (object:any)  => { this.shakeAnimation(this.kado); this.highLightMesh(this.kado); }, sound: this.chipsSound },
					{ name: 'goal', lastTrigger:0, cooldown: 3000, isGoal:true, points: 1000, object: this.goalObstacle, func: (object:any)  => { this.handleGoal(object); this.highLightMesh(this.goalmeshes[0]); this.highLightMesh(this.goalmeshes[1]); this.highLightMesh(this.backwall); }, sound:this.goalSound }									
				];
			}

			// Add lights to the scene
			this.environmentLight = new HemisphericLight("environmentLight", this.WORLD_ORIGIN.clone(), this.scene);
			this.environmentLight.intensity = 1;
			this.caseLight1 = new PointLight("caseLight1", new Vector3(0, 70, 80), this.scene);
			this.caseLight1.intensity = 0.8;
			this.caseLight2 = new PointLight("caseLight2", new Vector3(0, 70, -40), this.scene);
			this.caseLight2.intensity = 0.8;
			this.caseLight2.excludedMeshes = this.chevronLights;
			
				
			if (this.SHADOWS) {
				this.shadows = new ShadowGenerator(1024, this.caseLight2);
			}
			
			var startInterval:any = null;
			startInterval = setInterval(() => {
				if(!document.hidden) {
					this.createEventListeners();
					clearInterval(startInterval);						
					this.startGame()
				}
			}, 500);
		});		
		// console.log('game constructor end')	
	}
	
	// crude optimisation
	startOptimisingResolution() {
		let rescaleIndicatorCount = 0;
		setInterval(() => {
			let fps = this.engine.getFps();
			// ////console.log(`FPS ${Math.round(fps)} SCALE ${this.canvasScale} = ${CANVAS_SCALE_STEPS[this.canvasScale]}`);
			
			// check whether FPS indicates to scale up or down
			let newScale: boolean | null = null;
			let scaleSteps = this.CANVAS_SCALE_STEPS;
			if (fps > this.GOOD_FPS && this.canvasScale > 0) newScale = false;
			else if (fps < this.MIN_FPS && this.canvasScale < scaleSteps.length-1) newScale = true;
			
			// only rescale if the FPS crosses the treshold multiple times in a row
			if (newScale != null) {
				// ////console.log(`FPS ${Math.floor(fps)}!`);
				rescaleIndicatorCount++;
				if (rescaleIndicatorCount >= 4) {
					this.rescale(newScale);
					rescaleIndicatorCount = 0;
				}
			} else {
				if (rescaleIndicatorCount != 0) {
					// ////console.log("Meh");
					rescaleIndicatorCount = 0;
				}
			}
			
		}, 500);
	}
	
	setShadowMapResolution(resolution:number) {
		if(this.SHADOWS) {
			if(this.shadows) {
				for(let mesh of this.shadowMeshes) {
					this.shadows.removeShadowCaster(mesh);
				}
				this.shadows.dispose();
				this.shadows = null;
			}
			this.shadows = new ShadowGenerator(resolution, this.caseLight2);
			for(let mesh of this.shadowMeshes) {
				console.log('addShadowCaster', mesh);
				this.shadows.addShadowCaster(mesh);
			}
			this.currentShadowResolution = resolution;
		}
	}

	onMusicEnded() {
		//setTimeout( ()=> {
			this.playRandomMusic();
		//}, 1000);
	}

	playRandomMusic() {
		if(this.backgroundMusic.length > 1) {
			// if there are multiple tracks, pick one at random (prefer a new one); 
			let random = Math.floor(Math.random() * this.backgroundMusic.length);
			while(random == this.currentBgMusic) {
				random = Math.floor(Math.random() * this.backgroundMusic.length); 
			}
			this.currentBgMusic = random;
			this.playingMucic = this.backgroundMusic[this.currentBgMusic];
		} else {
			// if there is only one track, repeat that
			this.playingMucic = this.backgroundMusic[0];
		}

		// extra check to prevent errors .... play the selected music
		if(this.playingMucic) this.playingMucic.play();		
	}
	
	setSubSteps(s: number) {
		if (this.curSubSteps != s) {
			this.curSubSteps = s;
			this.physicsEngine.setFixedTimeStep(1/(60 * this.curSubSteps));
		}
	}
	
	setPresetCameraPos(pos:number) {
		let settings = this.getPresetCameraSettings(pos);
		this.camera.position = settings.position;
		this.camera.lockedTarget = settings.target;
		this.camera.fov = settings.fov;	
		
		if(pos == 7) {
			this.camera.attachControl(this.canvas);
		} else {
			this.camera.detachControl(this.canvas);
		}
		
		if(pos < 8) this.currentCameraPreset = pos;
	}
	
	getPresetCameraSettings(pos:number) {
		var position = this.camera.position;
		var target = this.camera.lockedTarget;
		var fov = 0.8;
		if (pos == 0) {
			position = new Vector3(0,28,95);
			target = this.WORLD_ORIGIN.clone();
		} else if (pos == 1) {
			position = new Vector3(40, 60, 78+20);
			target = this.WORLD_ORIGIN.clone()
		} else if (pos == 2) {
			position = new Vector3(20, 60, 58);
			target = this.WORLD_ORIGIN.clone()
		} else if (pos == 3) {
			let z = 2;
			position = new Vector3(40*z, 60*z, (78+20)*z);
			target = this.WORLD_ORIGIN.clone()
		} else if (pos == 4) {
			position = new Vector3(0,0,78);
			target = new Vector3(0, -20, 0)
		} else if (pos == 5) {
			position = new Vector3(0,150,77);
			target = new Vector3(0,15,0);
		} else if (pos == 6) {
			position = new Vector3(0,114,109);
			target = new Vector3(0,15,0);
		} else if (pos == 7) {
			position = new Vector3(0,0,0);
			target = null;
		} else if (pos == 8) {
			var ratio = this.canvas.width / this.canvas.height;
			if(ratio < 1) {
				target = this.PORTRAIT_CAMERA_TARGET.clone();
				position = new Vector3(0, 122.5, 92.5);
			} else {
				position = new Vector3(0,28,95);
				target = this.WORLD_ORIGIN.clone();
			}
		} else if (pos == 9) {
			var ratio = this.canvas.width / this.canvas.height;
			target = new Vector3(0, 10, 0);
			if(ratio >= 0.75 && ratio < 1) {
				target = new Vector3(0, 10, 0);
				// position = new Vector3(0, 112.5, 57);
				position = new Vector3(0, 75, 115);
			} else if(ratio >= 0.65 && ratio < 0.75) {
				target = new Vector3(0, 10, 0);
				// position = new Vector3(0, 112.5, 57);
				position = new Vector3(0, 95, 115);
			} else if(ratio >= 0.55 && ratio < 0.65) {
				target = new Vector3(0, 10, -5);
				// position = new Vector3(0, 112.5, 57);
				position = new Vector3(0, 120, 105);
				
			} else if(ratio >= 0.5 && ratio < 0.55) {
				target = new Vector3(0, 10, -5);
				// position = new Vector3(0, 112.5, 57);
				position = new Vector3(0, 135, 97.5);
			} 
			else if(ratio < 0.5) {
				target = new Vector3(0, 10, -5);
				// position = new Vector3(0, 112.5, 57);
				position = new Vector3(0, 155, 75);
			} else {
				position = new Vector3(0,28,95);
				target = this.WORLD_ORIGIN.clone();
			}
		}
		return {
			position: position,
			target: target,
			fov: fov
		}
	}
	
	spawnBall(worldPos: Vector3) {
		let pinBall = MeshBuilder.CreateSphere("pinBall", {diameter: this.pinBallDiameter, segments:3}, this.scene);
		pinBall.physicsImpostor = new PhysicsImpostor(pinBall, PhysicsImpostor.SphereImpostor, {mass:1, restitution: this.BALL_RESTITUTION, friction: this.BALL_FRICTION}, this.scene);
		
		pinBall.material = this.ballMaterial;
		pinBall.position = worldPos;
		pinBall.isPickable = false;
		
		this.soccerBall.setParent(pinBall);
		this.soccerBall.position = new Vector3(0,0,0);
		this.soccerBall.setEnabled(true);
		this.shadowMeshes.push(pinBall);
		
		if (CCD) {
			let bod = pinBall.physicsImpostor.physicsBody;
			bod.setCcdMotionThreshold(this.pinBallDiameter / 2);
			bod.setCcdSweptSphereRadius(this.pinBallDiameter / 2);
		}

		if(this.CONSOLE_DEBUGGING) {
			// @ts-ignore
			window.pinball = pinBall;
		}
		return pinBall;
	}
	
	spawnTestBall() {
		this.spawnBall(new Vector3(-10 + Math.random() * 20, 20, 0));
	}
	
	createObstacle(scene:Scene, position:Vector3, w:number, h:number, d:number) {
		var obstacle = MeshBuilder.CreateBox("obstacle", {width:w, height:h, depth:d}, scene);
		obstacle.position = position;
		obstacle.physicsImpostor = new PhysicsImpostor(obstacle, PhysicsImpostor.BoxImpostor, {mass:0, restitution: 0, friction:0}, scene);
		
		obstacle.setParent(this.pinballMachine);
		obstacle.visibility = this.OBSTACLES_MODELS_VISIBILITY;
		return obstacle;
	}
	
	onRender(delta:number) {
		if (this.pinBall && this.pinBall.physicsImpostor) {
			if (this.ballHittedBottom()) {
				this.onOpponentsGoal();
			} else if(this.ballOutOfBounds()) {
				this.startAutoFirePinBall(true);
			}
		}
		if (!this.plungerFiredBall && !this.plungerIsFiring){ // && pinBall && pinBall.position.x < -30) {
			this.correctBallposition();
		}

		if (this.plunger) {
			if (this.arrowIsDown || this.quicklaunch) {
				this.pullDownPlunger(this.quicklaunch ? delta * 4 : delta);
				this.quicklaunch = this.plunger.position.z < this.plungerMin.z;// moment().valueOf() < this.quicklaunchTime;
			} else if(this.draggingPlunger) {
				this.pullDownPlunger(delta);
			} else {
				this.releasePlunger(delta);	
			}
		}
		this.moveGoalieObstacle(delta);
	}
	
	onOpponentsGoal() {
		this.loseSound.play();
		this.startAutoFirePinBall();
	}

	addBall() {
		if(!this.pinBall) {
			this.pinBall = this.spawnBall(this.BALL_START_POSITION.clone());
			this.pinBall.setParent(this.pinballMachine);
		}
	}
	
	removeBall() {
		if(this.pinBall) {
			this.pinBall.physicsImpostor.dispose();
			
			this.pinBall.physicsImpostor = null;
			this.pinBall.dispose();
			this.pinBall = null;
		}
	}
	
	resetBall(wasOutOfBounds = false) {
		if(this.plunger != null) {
			this.plunger.position = new Vector3(this.plungerMax.x, this.plungerMax.y, this.plungerMax.z); // make sure the plunger is in the starting position
			this.plungerIsFiring = false;
			this.plungerMovedDown = false;
			this.plungerFiredBall = false;
		}
		if(this.pinBall && this.pinBall.physicsImpostor) {
			if(!this.playInfinite && !wasOutOfBounds
				) {
				--this.ballsInReserve;
				if(this.ballsInReserve <= 0) {
					this.ballsInReserve = 0;
					this.handleNoMoreBalls();
				}
				this.updateBallUi();
			}
			
			// engine.clear(this.CLEAR_COLOR, true, true);
			this.pinBall.visibility = this.PHYSICS_MODELS_VISIBILITY;
			this.pinBall.physicsImpostor.setAngularVelocity(this.zeroVelocity);
			this.pinBall.physicsImpostor.setLinearVelocity(this.zeroVelocity);
			this.pinBall.position = this.BALL_START_POSITION.clone();
			this.pinBall.visibility = this.GRAPHIC_MODELS_VISIBILITY;
		}
	}
	
	checkLightIntersection(delta:number) {
		if(this.chevronLights && this.pinBall) {
			let lightsAmount = this.chevronLights.length;
			for(let i = 0; i < lightsAmount; i++) {
				let light:any = this.chevronLights[i];
				let trigger = this.chevronTriggers[i];
				if(this.pinBall.intersectsPoint(this.localToGlobal(trigger))) {
					if(!this.highlightLayer.hasMesh(light)) {
						if(light && light.material) {
							light.material.emissiveColor = this.chevronLightColor;
							this.highlightLayer.addMesh(light, this.chevronLightColor);
						}
					}
				} else {
					if(this.highlightLayer.hasMesh(light))  {
						if(light && light.material) {
							setTimeout(() => { light.material.emissiveColor = this.chevronLightOffColor; this.highlightLayer.removeMesh(light); this.highlightLayer._disposeMesh(light);light = null;	trigger = null; }, 150);					
						}
					}
				}
				
			}
		}
	}
	
	highLightMesh(mesh:any, duration:number = 300, color:Color3 = this.highlightColor) {
		if (mesh) {
			var thisMesh:Mesh = (mesh as Mesh);
			if(!this.highlightLayer.hasMesh(thisMesh)) {
				this.highlightLayer.addMesh(thisMesh, color);
				setTimeout(() => { this.highlightLayer.removeMesh(thisMesh); this.highlightLayer._disposeMesh(thisMesh); if(!this.gamePaused) this.engine.clear(this.CLEAR_COLOR, true, true); }, duration);
			}
			
		}
	}
	//
	highlightObstacle(mesh:any, defaultMat:StandardMaterial, highlightMat:StandardMaterial) {
		if(mesh && defaultMat && highlightMat) {
			mesh.material = highlightMat;
			var timeout:any = null;
			timeout = setTimeout(() => { mesh.material = defaultMat; clearTimeout(timeout); }, 300);
		}
	}
	
	pullDownPlunger(delta:number) {
		if (this.plunger) {
			if (this.plunger.position.z < this.plungerMin.z) {
				if(this.draggingPlunger) {
					let plungerPos = this.plungerMax.z - this.dragDistance;
					if(plungerPos < this.plungerMax.z) plungerPos = this.plungerMax.z;
					if(plungerPos > this.plungerMin.z) plungerPos = this.plungerMin.z;
					this.plunger.position.z = plungerPos;
					this.plungerMovedDown = true;
				} else {
					this.plunger.position.z += this.plungerMoveDownStep * delta;
					this.plungerMovedDown = true;
				}
			}
		}
	}
	
	releasePlunger(delta:number) {
		if (this.plunger && this.pinBall && this.pinBall.physicsImpostor) {
			if (this.plungerIsFiring) {
				var v = ++this.plungerFireSteps * this.plungerFireStepSize;
				var dx = v * v;
				var y = this.plunger.position.z - dx ;
				if (y < this.plungerMax.z) {
					y = this.plungerMax.z;
					this.plunger.position.z = y;
					this.plungerIsFiring = false;
					this.plungerFiredBall = false;
				} else {
					this.plunger.position.z = y;
					if(!this.releasePlungerSound.isPlaying) { this.releasePlungerSound.play(); }
				}
				if (!this.plungerIsFiring && this.pinballIsInVicinityOfPlunger()) {
					this.plungerIsForce = -(this.plungerFireForceMultiplier * Math.abs(this.plungerMax.z - this.plunger.position.z));
					if(!this.plungerFiredBall) this.pinBall.physicsImpostor.applyImpulse(this.localToGlobal(new Vector3(0, this.plungerIsForce, this.plungerIsForce)), this.pinBall.getAbsolutePosition());
					this.plungerFiredBall = true;
				}
			} else {
				if (this.plunger.position.z >= this.plungerMax.z) {
					this.plungerIsFiring = true;
					this.plungerFireSteps = 0;
					this.plungerIsForce = -(this.plungerFireForceMultiplier * Math.abs(this.plungerMax.z - this.plunger.position.z));
					if (this.pinballIsInVicinityOfPlunger()) {
						if(!this.plungerFiredBall) this.pinBall.physicsImpostor.applyImpulse(this.localToGlobal(new Vector3(0, this.plungerIsForce, this.plungerIsForce)), this.pinBall.getAbsolutePosition());
						this.plungerFiredBall = true;
					} else {
						this.plungerFiredBall = false;
					}
				}
			}
		}
	}
	
	pinballIsInVicinityOfPlunger() {
		if (this.pinBall && this.plunger && this.pinBall.position.x < -30) {
			return (Math.abs(this.pinBall.position.x - this.plunger.position.x) < 0.5 &&
			Math.abs(this.pinBall.position.y - this.plunger.position.y) < 0.5 &&
			Math.abs(this.pinBall.position.z - this.plunger.position.z) < this.plungerPinballDistance);
		}
		return false;
	}
	
	pinballIsUnderPlunger() {
		if (this.pinBall && this.plunger&& this.pinBall.position.x < -30) {
			return (Math.abs(this.pinBall.position.x - this.plunger.position.x) < 0.5 &&
			Math.abs(this.pinBall.position.y - this.plunger.position.y) < 0.5 &&
			this.plunger.position.z - this.pinBall.position.z < this.plungerPinballDistance - 1);
		}
		return false;
	}
	
	correctBallposition() {
		if (this.pinballIsUnderPlunger()) {
			this.pinBall.position.x = this.plunger.position.x;
			this.pinBall.position.y = this.pinBallDiameter / 2;
			this.pinBall.position.z = this.plunger.position.z - this.plungerPinballDistance;
			return true;
		}
		this.pinBall.position.y = this.pinBallDiameter / 2;
		return false;
	}
	
	moveGoalieObstacle(delta:number) {
		if(this.goalieObstacle && delta > 0) {
			this.goalieX = this.goalieObstacle.position.x;
			if(this.goalieX <= this.goalieMin) {
				this.goalieGoingRight = true;
			} else if(this.goalieX >= this.goalieMax) {
				this.goalieGoingRight = false;
			} else {
				if(Math.random() < 0.15) {
					this.goalieChangeDirection();
				}
			}
			if(this.goalieGoingRight) {
				this.goalieX += this.goalieMovement * delta;
				if(this.goalieX > this.goalieMax) {
					this.goalieX = this.goalieMax;
				}
			} else {
				this.goalieX -= this.goalieMovement * delta;
				if(this.goalieX < this.goalieMin) {
					this.goalieX = this.goalieMin;
				}
			}
			this.goalieObstacle.position.x = this.goalieX;
		}
	}
	
	goalieChangeDirection() {
		this.goalieGoingRight = !this.goalieGoingRight;
		if(this.pinBall && Math.random() > 0.1) {
			if(this.pinBall.position.x > -30) {
				var ballX = this.pinBall.position.x;
				var ballZ = this.pinBall.position.z;
				if(ballZ > 10 || ballZ < -40) {
					if( 0 > ballX) {
						this.goalieGoingRight = true;
					} else if ( 0 < ballX) {
						this.goalieGoingRight = false;
					} else {
						this.goalieGoingRight = Math.random() > 0.5;
					}
				} else {
					if( 0 < ballX) {
						this.goalieGoingRight = true;
					} else if ( 0 > ballX) {
						this.goalieGoingRight = false;
					} else {
						this.goalieGoingRight = Math.random() > 0.5;
					}
				}
			}
		}
	}
	
	ballHittedBottom() {
		return (this.pinBall && this.pinBall.position.x < 4.5 && this.pinBall.position.x > -4.5 && this.pinBall.position.z > 56);
	}

	ballOutOfBounds() {
		return (this.pinBall && (this.pinBall.position.z > 56 && !this.ballHittedBottom()) || this.pinBall.position.z <= -50.5 || this.pinBall.position.x <= -33.5 || this.pinBall.position.x >= 33.5);
	}
	
	clearAutoFirePinBall() {
		if (this.autoFireId) {
			clearTimeout(this.autoFireId);
			this.autoFireId = null;
		}
	}
	
	startAutoFirePinBall(wasOutOfBounds = false) {
		this.clearAutoFirePinBall();
		this.resetBall(wasOutOfBounds);
	}
	
	localToGlobal(vect:Vector3) {
		return Vector3.TransformCoordinates(vect, this.pinballMachine.getWorldMatrix().clone());
	}
	
	globalToLocal(vect:Vector3) {
		return Vector3.TransformCoordinates(vect, this.pinballMachine.getWorldMatrix().clone().invert());
	}
	
	updateCamera() {
		this.setPresetCameraPos(this.isPhoneDevice() ? 9 : this.currentCameraPreset);
	}
	
	updateFps() {		
		if(this.SHOW_FPS || this.fpsLabel) {
			if(this.fpsLabel.hidden) this.fpsLabel.hidden = false
			this.fpsLabel.innerHTML = this.engine.getFps().toFixed() + " fps";
		}	
	}
	
	runRenderList() {
		for (let f of this.onRenderList) {
			f();
		}
	}
	//
	unIndexMeshes() {
		for(let mesh of this.meshesToUnIndex) {
			if(mesh.convertToUnIndexedMesh)	mesh.convertToUnIndexedMesh();
			if(mesh.cullingStrategy) mesh.cullingStrategy = AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
		}
	}
	
	freezeMeshWorldMeshes() {
		for(let mesh of this.meshesToFreezeWorldMatrix) {
			if(mesh.freezeWorldMatrix)	mesh.freezeWorldMatrix();
		}
	}
	
	scoreAnimation(score:number, position:Vector3, object:AbstractMesh) {
		let element = document.createElement('div');
		element.innerHTML = `${score}<span>${score}</span>`;
		element.classList.add('point-indicator');
		let container = document.getElementById("gamepoint-wrap");
		container.appendChild(element);
		
		var transformationMatrix = this.camera.getTransformationMatrix();
		var projectedPosition = Vector3.Project(object.position, Matrix.Identity(), transformationMatrix, this.camera.viewport.toGlobal(this.engine.getRenderWidth() * this.engine.getHardwareScalingLevel(), this.engine.getRenderHeight() * this.engine.getHardwareScalingLevel()));
		
		let newLeft:string = `${projectedPosition.x - $(element).width()}px`;
		let newTop:string = `${projectedPosition.y - $(element).height()}px`;
		element.style.top = newTop;
		element.style.left = newLeft;
		
		element.addEventListener("animationend", () => {
			container.removeChild(element);
		});
		element.addEventListener("webkitAnimationEnd", () => {
			container.removeChild(element);
		});
		element.addEventListener("oAnimationEnd", () => {
			container.removeChild(element);
		});
		element.addEventListener("MSAnimationEnd", () => {
			container.removeChild(element);
		});
	}
	
	shakeAnimation(item:TransformNode) {
		let shakeAnim = new Animation("shakeAnimation", "position.x", 60, Animation.ANIMATIONTYPE_FLOAT);
		let startX = item.position.x;
		let directionX = Math.random() < 0.5 ? 1 : -1;
		let directionZ = Math.random() < 0.5 ? 1 : -1;
		let shakeKeys = [
			{
				frame: 0,
				value: startX
			},
			{
				frame: 10,
				value: startX + 0.3 * directionX
			},
			{
				frame: 20,
				value: startX - 0.3 * directionX
			},
			{
				frame: 30,
				value: startX + 0.2 * directionX
			},
			{
				frame: 40,
				value: startX - 0.2 * directionX
			},
			{
				frame:50,
				value: startX + 0.1 * directionX
			},
			{
				frame: 60,
				value: startX
			}
		];
		shakeAnim.setKeys(shakeKeys);
		let shakeAnim2 = new Animation("shakeAnimation2", "position.z", 60, Animation.ANIMATIONTYPE_FLOAT);
		let startZ = item.position.z;
		let shakeKeysZ = [
			{
				frame: 0,
				value: startZ
			},
			{
				frame: 10,
				value: startZ + 0.3 * directionZ
			},
			{
				frame: 20,
				value: startZ - 0.3 * directionZ
			},
			{
				frame: 30,
				value: startZ + 0.2 * directionZ
			},
			{
				frame: 40,
				value: startZ - 0.2 * directionZ
			},
			{
				frame:50,
				value: startZ + 0.1 * directionZ
			},
			{
				frame: 60,
				value: startZ
			}
		];
		shakeAnim2.setKeys(shakeKeysZ);
		this.scene.beginDirectAnimation(item, [shakeAnim, shakeAnim2], 0, 60, false, 2, () => {
			item.position.x = startX;
			item.position.z = startZ;
			this.scene.removeAnimation(shakeAnim);
			this.scene.removeAnimation(shakeAnim2);
		});
	}

	toggleSound() {
		try {
			if(this.scene.audioEnabled) {
				this.scene.audioEnabled = false;
			} else {
				this.scene.audioEnabled = true;
			}
		} catch(e) {
			console.log(e);
		}
		this.changeSmallButtonImage(this.scene.audioEnabled ? this.soundOnImage : this.soundOffImage);
	}
	
	onToggleSound(e:any) {
		setTimeout(() => {
			if(this.soundToggle.classList.contains('active')) {
				this.scene.audioEnabled = true;
			} else {
				this.scene.audioEnabled = false;
			}
		}, 300);
	}
	
	onToggleInfinite(e:any) {
		setTimeout(() => {
			if(this.infiniteToggle.classList.contains('active')) {
				this.playInfinite = true;
			} else {
				this.playInfinite = false;
			}
		}, 300);
	}
	
	handleGoal(object:any) {
		//@ts-ignore
		window.showconfetti();
		this.showGoalImage();
		this.updateBallUi(true);
	}
	
	showGoalImage() {
		this.goalImageShowing = true;	
		this.backboardChangeImage(this.goalBackboardImage, ()=> {
			setTimeout(() => {
				this.backboardGoBackToDefault();
			}, 1000);
		});
	}
	
	showBonus(onClose:()=>void) { // aanpassen zodat de smartvertizing modal aangeroepen wordt, die de cadeau toont
		if(!this.SHOW_POPUPS) {
			onClose();
			return;
		}
		this.pauseGame();
		$('#dialog_bonus').modal({backdrop: 'static', keyboard: false}) ;
		$('#dialog_bonus').on('hidden.bs.modal', () => {
			this.unpauseGame();
			onClose();
		});
	}
	
	showGameover(onClose:()=>void) {
		if(!this.SHOW_POPUPS) {
			onClose();
			return;
		}
		this.pauseGame();
		$('#modal-lost').modal({backdrop: 'static', keyboard: false}) ;
		$('#modal-lost').on('hidden.bs.modal', () => {
			this.unpauseGame();
			this.infiniteToggle.classList.add('active');
			onClose();
		})
	}
	
	toggleHighscoreButton(show:boolean = false) {
		if(show) $('#btn-submitscore').show();
		else $('#btn-submitscore').hide();
	}

	handleNoMoreBalls() {		
		this.pauseGame();
		if(!this.checked_bonus_balls) {
			if(this.bonus_lives > 0 && this.bonus_type_id != 0) {		
				// add server check for bonus_lives
				$.ajax(this.apiUrl,
				{
					method: "GET",
					data: {
						game_id: this.gameId,
						track_token : this.trackToken,
						func: "eligibleforbonuslives",
						device: this.getDevice(),
						agent: this.userAgent,
						email: this.playerData.email, 
						code: this.playerData.code,
						player_id: this.playerData.id,
						ref: this.ref
					},
					complete: (resp) => {
						var json = JSON.parse(resp.responseText);
						if(json.success) {					
							$('#bonus-no').off('click');
							$('#bonus-yes').off('click');
							if(this.bonus_type_id == 1) { /// newsletter
								$('#bonus-desc').html('Schrijf je in voor de nieuwsbrief van De Tuinen Naaldwijk en ontvang een extra speelbal om kans te maken op fantastische cadeaus.');
								$('#bonus-yes').on('click', () => {
									this.checked_bonus_balls = true;
									this.handleBonusNewsLetter();
								});
							}				
							if(this.bonus_type_id == 2) { // survey
								$('#bonus-desc').html('Vul een korte vragenlijst (1 vraag) in en ontvang een extra bal om kans te maken op fantastische cadeaus.');
								$('#bonus-yes').on('click', () => {
									this.checked_bonus_balls = true;
									this.handleBonusSurvey();
								});
							}
							$('#extra-check-email').val(this.playerData.email);
							
							var onDecline = () => {
								console.log('decline bonus');
								this.checked_bonus_balls = true;
								$('#modal-bonus').modal('hide');
								this.showSubmitscore();		
							}
							$('#bonus-no').on('click', () => onDecline());
			
							$('#modal-bonus').modal({backdrop:"static"});
							return;			
						}
						this.showSubmitscore();
					}
				});
				return;
			}
		}			
		this.showSubmitscore();
	}
	
	recheckBonusLives() {
		$.ajax(this.apiUrl,
		{
			method: "GET",
			data: {
				game_id: this.gameId,
				track_token : this.trackToken,
				func: "bonuslives",
				device: this.getDevice(),
				agent: this.userAgent,
				email: this.playerData.email, 
				player_id: this.playerData.id,
				code: this.playerData.code,
				ref: this.ref
			},
			complete: (resp) => {
				var json = JSON.parse(resp.responseText);
				if(json.success) {
					if(json.bonus_lives > 0) {
						this.ballsInReserve = json.bonus_lives;	
						this.updateBallUi();
						this.unpauseGame();
						return;
					}
				} 
				this.showSubmitscore();
			}
		})
	}

	handleBonusNewsLetter() {		
		$.ajax(this.apiUrl,
		{
			method: "GET",
			data: {
				game_id: this.gameId,
				track_token : this.trackToken,
				func: "updateplayer",
				device: this.getDevice(),
				agent: this.userAgent,
				email: this.playerData.email,
				newsletter_opt_in: "yes",
				newsletter_2_opt_in: "yes",
				ref: this.ref
			},
			complete: (resp) => {
				var json = JSON.parse(resp.responseText);
				if(json.success) {									
					this.recheckBonusLives();
				} else {
					$('#modal-bonus').modal('hide');
					this.showSubmitscore();
				}
			}
		})
	}

	handleBonusSurvey() {
		var onDone = () => {
			this.recheckBonusLives();
		}
		var onClose = () => {
			this.showSubmitscore();
		}
		
		window.dispatchEvent(new CustomEvent('openBonusSurvey', {detail:{
			onClose: onClose,
			onDone: onDone
		}}));
	}
	
	showSubmitscore() {
		if(!this.SHOW_POPUPS) {
			return;
		}
		this.pauseGame();
		
		window.dispatchEvent(new CustomEvent('getPrizeBasedOnScore', {detail:{score:this.score, highscoreToBeat: this.highscoreToBeat}}));
	}
	
	resetScore() {	
		dispatchEvent(this.scoreResetEvent);
		this.score = 0;
		this.scoreElement.innerHTML = "" + this.score;
	}
	
	updateHighScore(highscores:any[]) {
		if(highscores) {
			highscores.sort((a,b) => {
				return b.score - a.score;
			});
			highscores = highscores.slice(0, 10);
			let lowestScore = 0;
			lowestScore = parseInt(highscores[0] ? highscores[0].score : "0");
			for(let score of highscores) {
				if(score.score != null) {
					lowestScore = Math.min(lowestScore, parseInt(score.score));
				}
			}
			this.highscoreToBeat = lowestScore;
		}
	}
	
	renderScene(ts:number) {
		// console.log('renderScene', ts);
		if(this.SHOW_FPS) this.updateFps();
		if(!this.gamePaused) {
			if(this.lastRenderRun == 0) this.lastRenderRun = 0;
			var delta = (ts - this.lastRenderRun) / 1000;
			if(this.ledboardTexture) this.ledboardTexture.uOffset += 0.5 * delta;
			if(this.pinBall && this.pinBall.position.x < -30) this.checkLightIntersection(0);
			this.engine.beginFrame();
			// this.engine.clear(this.CLEAR_COLOR, true, true);
			// console.log('this.lastActualRender', this.lastActualRender);
			// if(this.lastActualRender + delta > 1/1) {
				// console.log('actual render');
				this.scene.render(!this.introAnimationDone);
			// } else {
				// console.log('skip render');
				// this.lastActualRender += delta;
			// }
			this.engine.endFrame();
			this.lastRenderRun = ts;
			// if(moment().valueOf() > this.nextShine) buttonShine();
		}
	}
	
	subLoop(ts:number) {
		if(!this.gamePaused && this.introAnimationDone) {
			if(this.lastSubloopRun == 0) this.lastSubloopRun = 0;
			var delta = (ts - this.lastSubloopRun) / 1000;
			this.rightFlipper.setPowered(this.rightIsPowered);
			this.leftFlipper.setPowered(this.leftIsPowered);
			this.onRender(delta);
			
			this.lastSubloopRender = 0;
			
			this.lastSubloopRun = ts;
		}
	}
	
	animationFrame(ts:number) {
		// console.log('animationFrame');
		this.renderScene(ts);
		this.subLoop(ts);
		requestAnimationFrame((ts) => this.animationFrame(ts));
	}
	
	startGame() {
		// console.log('this.startGame');
		var endLoad = moment().valueOf();
		this.rotateMachine();
		this.unIndexMeshes();

		requestAnimationFrame((ts) => this.animationFrame(ts))
		
		
		this.unpauseGame();
		
		this.optimizer.start();
		
		if(this.isMobileDevice()) {
			this.setPresetCameraPos(9);
		} else {
			this.camera.position = new Vector3(-120, 35, 50);
			this.camera.lockedTarget = this.WORLD_ORIGIN.clone();
		}
		
		// this.camera.rotation = new Vector3(0.24, 1.9, -0.0020);
		setTimeout(() => {
			this.backboardContext.drawImage(this.backboardImages[this.currentBackboardImageIndex], 0, 0);
			this.backboardTexture.update();
			this.freezeMeshWorldMeshes();
			this.addBall();
			this.setShadowMapResolution(this.currentShadowResolution);

			// scene.freezeActiveMeshes();
		}, 1000);
		
		setTimeout(() => {
			this.startOptimisingResolution();
			this.hideLoadingScreen();
			this.updateBallUi(true);
			this.engine.clear(this.CLEAR_COLOR, true, true);
			this.scene.audioEnabled = true;
			this.changeSmallButtonImage(this.soundOnImage);
			this.playRandomMusic();
			// setTimeout(() => backgroundMusic2.play(), 10000);
			if(this.SKIP_INTRO_ANIM) {
				this.setPresetCameraPos(this.currentCameraPreset);
				this.onIntroAnimationDone();
				if(this.loadingScreen) {
					this.loadingScreen.style.opacity = "0";
				}
			} else {
				setTimeout(() => this.animateCamera(), 1000);
			}
		}, 4500 - (endLoad - this.startLoad));
	}
	
	onIntroAnimationDone() {
		this.goaliePlane.position = this.getGoaliePlanePosition();
		this.kado.position = this.kado_position;
		this.sled.position = this.sled_position;
		this.tree.position = this.tree_position;
		this.snowman.position = this.snowman_position;
		
		this.introAnimationDone = true;

		if(this.isMobileDevice()) {
			$('.mobile-loading-wrap').addClass('show-tutorial');
			$('.mobile-loading-wrap').off('click tap');
			$('.mobile-loading-wrap').on('click tap', () => {
				$('.mobile-loading-wrap').removeClass('show-tutorial')
			})
			setTimeout(() => {
				$('.mobile-loading-wrap').removeClass('show-tutorial')
			}, 3000);
		}


		this.gameStart.play();

		this.updateCamera();

		this.snowman.physicsImpostor.forceUpdate();
	}
	
	updateBallUi(animate:boolean = false) {
		if(animate) {
			this.ballUi.addClass("animate");
			setTimeout(() => {
				this.ballUi.removeClass("animate");
			}, 1000);
		} else {
			this.ballUi.removeClass("animate");
		}
		this.ballCount.text(this.ballsInReserve);
	}
	
	hideLoadingScreen() {
		if(this.loadingContent && this.loadingContent.style) this.loadingContent.style.opacity = "0";
		this.canvas.style.opacity = "1";
	}


	
	buildScene(onDone:()=>void) {
		var stepsBeforeReady = 18;
		var checkLoadDone = () => {
			console.log('before--', this.checksBeforeReady);
			if(--this.checksBeforeReady <= 0) {
				onDone();
			}
			console.log('after--', this.checksBeforeReady);
		}
		
		// let testbox = MeshBuilder.CreateBox("testbox", {width:2, height:2, depth:1, sideOrientation:Mesh.BACKSIDE}, this.scene);
		// testbox.position = PORTRAIT_CAMERA_TARGET;
		
		this.skybox = MeshBuilder.CreateBox("skybox", {width:250, height:250, depth:250, sideOrientation:Mesh.BACKSIDE}, this.scene);
		
		this.skyboxMaterial = new StandardMaterial("skybox", this.scene);
		// let cubeTexture = CubeTexture.CreateFromImages(["./images/Untitled-1.jpg?nocache=" + moment().valueOf(),"./images/Untitled-1.jpg?nocache=" + moment().valueOf(),"./images/Untitled-1.jpg?nocache=" + moment().valueOf(),"./images/Untitled-1.jpg?nocache=" + moment().valueOf(),"./images/Untitled-1.jpg?nocache=" + moment().valueOf(),"./images/Untitled-1.jpg?nocache=" + moment().valueOf()], this.scene, true);
		this.skyboxTexture = CubeTexture.CreateFromImages(["./assets/3d-models/Images/background.jpg?nocache=" + moment().valueOf(),"./assets/3d-models/Images/floor.jpg?nocache=" + moment().valueOf(),"./assets/3d-models/Images/background.jpg?nocache=" + moment().valueOf(),"./assets/3d-models/Images/background.jpg?nocache=" + moment().valueOf(),"./assets/3d-models/Images/floor.jpg?nocache=" + moment().valueOf(),"./assets/3d-models/Images/background.jpg?nocache=" + moment().valueOf()], this.scene, true);
		
		this.skyboxMaterial.diffuseColor = new Color3(0, 0, 0);
		this.skyboxMaterial.specularColor = new Color3(0, 0, 0);
		this.skyboxMaterial.reflectionTexture = this.skyboxTexture;
		this.skyboxMaterial.reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE;
		this.skyboxMaterial.freeze();
		this.skybox.rotation.y = Math.PI;

		this.skybox.position = new Vector3(0, 75, 65);
		this.skybox.material = this.skyboxMaterial;
		
		this.floor = MeshBuilder.CreateGround("floor", {width:72, height:114}, this.scene);
		this.floor.physicsImpostor = new PhysicsImpostor(this.floor, PhysicsImpostor.BoxImpostor, {mass:0, restitution: 0, friction: this.FLOOR_FRICTION}, this.scene);
		
		if (this.SHADOWS) this.floor.receiveShadows = true;
		this.floor.setParent(this.pinballMachine);
		this.floor.position.y = 0.2;
		this.floor.position.z = 10;
		this.meshesToUnIndex.push(this.floor);
		this.meshesToFreezeWorldMatrix.push(this.floor);
		
		this.grassTexture = new Texture("./assets/3d-models/Images/winter-Pinball-ice-texture.jpg?nocache=" + moment().valueOf(), this.scene);
		this.grassTexture.uScale = 10;
		this.grassTexture.vScale = 10;
		this.grassTexture.isCube = false;
		this.grassTexture.coordinatesMode = 2; // PLANAR_MODE
		
		this.grassMaterial = new StandardMaterial("grassMaterial", this.scene);
		this.grassMaterial.diffuseTexture = this.grassTexture;
		// grassMaterial.diffuseColor = new Color3(1,1,1);
		this.grassMaterial.specularColor = new Color3(0.2,0.2,0.2);

		this.fieldLinesTexture = new Texture("./assets/3d-models/Images/pinball-fieldlines.png?nocache=" + moment().valueOf(), this.scene);
		this.fieldLinesTexture.hasAlpha = true;
		
		this.fieldLinesMaterial = new StandardMaterial("fieldLinesMaterial", this.scene);
		this.fieldLinesMaterial.diffuseTexture = this.fieldLinesTexture;
		this.fieldLinesMaterial.specularColor = new Color3(0.1,0.1,0.1);
		this.fieldLinesMaterial.useAlphaFromDiffuseTexture = true;
		this.fieldLinesMaterial.zOffset = -1;
		// this.fieldLinesMaterial.disableDepthWrite = true;
		// this.fieldLinesMaterial.needDepthPrePass = false;
		this.fieldLinesMaterial.freeze();
		
		this.floor.alphaIndex = 1;
		this.floor.material = this.fieldLinesMaterial;
		
		this.ceiling = MeshBuilder.CreateBox("ceiling", {width:72, height:1, depth:120}, this.scene);
		this.ceiling.physicsImpostor = new PhysicsImpostor(this.floor, PhysicsImpostor.BoxImpostor, {mass:0, restitution: 1, friction: 0.01}, this.scene);
		
		this.ceiling.position.y = 9;
		this.ceiling.visibility = this.PHYSICS_MODELS_VISIBILITY;
		this.ceiling.rotation.x = this.machineAngle * 0.75;
		this.ceiling.isPickable = false;
		this.ceiling.setParent(this.pinballMachine);
		this.meshesToUnIndex.push(this.ceiling);
		this.meshesToFreezeWorldMatrix.push(this.ceiling);
		
		this.frontwall = MeshBuilder.CreateBox("frontwall", {width:72, height:90, depth:1}, this.scene);
		// frontwall.physicsImpostor = new PhysicsImpostor(frontwall, PhysicsImpostor.BoxImpostor, {mass:0, restitution: 0.8, friction: 0.01, }, this.scene);
		this.frontwall.position.z = this.BOTTOM_POS_Z;
		this.frontwall.visibility = this.PHYSICS_MODELS_VISIBILITY;
		this.frontwall.rotation.x = this.machineAngle
		this.frontwall.isPickable = false;
		this.meshesToUnIndex.push(this.frontwall);
		this.meshesToFreezeWorldMatrix.push(this.frontwall);
		this.frontwall.setParent(this.pinballMachine);
		
		this.ballTexture = new Texture("./assets/3d-models/Images/soccer-ball.jpg?nocache=" + moment().valueOf(), this.scene);
		this.ballMaterial = new StandardMaterial("metalMaterial", this.scene);
		this.ballTexture.coordinatesMode = 1;
		this.ballMaterial.alpha = 0;
		this.ballMaterial.diffuseColor = new Color3(0.737254, 0.619608, 0.345098);
		// this.ballMaterial.diffuseTexture = this.ballTexture;
		this.ballMaterial.specularColor = new Color3(1,1,1);
		this.ballMaterial.freeze();
		
		var simpleMaterial = new StandardMaterial("simpleMaterial", this.scene);
		simpleMaterial.diffuseColor = new Color3(1,1,1);
		simpleMaterial.specularColor = new Color3(1,1,1);
		simpleMaterial.emissiveColor = new Color3(1,1,1);
		simpleMaterial.wireframe = true;
		simpleMaterial.freeze();
		
		
		let goalieImage = this.getRandomGoalie();
		
		this.goalObstacle = this.createObstacle(this.scene, new Vector3(0, 5, -46), 30, 10, 1);
		
		this.goaliePlane = MeshBuilder.CreatePlane("goaliePlane", {height:9, width:9}, this.scene);
		this.goaliePlane.billboardMode = Mesh.BILLBOARDMODE_ALL;
		this.goaliePlane.position = this.getGoaliePlanePosition().clone();
		this.goaliePlane.position.y += 300;
		this.goaliePlane.isPickable = false;
		this.goalieObstacle = MeshBuilder.CreateBox("goalieObstacle", {height: 11, width:7, depth:1}, this.scene);
		this.goaliePlane.setParent(this.goalieObstacle);
		this.goaliePlane.alphaIndex = 9;
		this.goalieObstacle.visibility = this.OBSTACLES_MODELS_VISIBILITY;
		this.goalieObstacle.position = new Vector3(0,3,-39);
		this.goalieObstacle.rotationQuaternion = Quaternion.RotationAxis(this.yAxis, Math.PI);
		this.goalieObstacle.isPickable = false;
		
		this.goalieObstacle.physicsImpostor = new PhysicsImpostor(this.goalieObstacle, PhysicsImpostor.BoxImpostor, {mass:0, restitution: this.GOALIE_RESTITUTION, friction:this.OBSTACLE_FRICTION}, this.scene);
		
		this.goalieObstacle.setParent(this.pinballMachine);
		this.meshesToUnIndex.push(this.goalieObstacle);
		this.goalieTexture = new Texture("./assets/3d-models/Images/"+goalieImage+"?nocache=" + moment().valueOf(), this.scene);
		this.goalieTexture.hasAlpha = true;
		this.goalieTexture.coordinatesMode = 4;
		this.goalieTexture.isCube = false;
		this.goalieTexture.invertZ = true;
		this.goalieMaterial = new StandardMaterial("goalieMaterial", this.scene);
		this.goalieMaterial.zOffset = -99;
		this.goalieMaterial.diffuseColor = new Color3(1,1,1);
		this.goalieMaterial.specularColor = new Color3(0,0,0);
		this.goalieMaterial.diffuseTexture = this.goalieTexture;
		this.goalieMaterial.useAlphaFromDiffuseTexture = true;
		this.goalieMaterial.freeze();
		this.goaliePlane.material = this.goalieMaterial;
		this.goaliePlane.rotationQuaternion = Quaternion.RotationAxis(this.yAxis, 0);
		
		this.defaultBackboardImage = new Image();
		this.goalBackboardImage = new Image();
		this.backboardTexture = new DynamicTexture("backboardTexture", {width:1170, height:445}, this.scene, false);
		this.backboardMaterial = new StandardMaterial('backboardMaterial', this.scene);
		this.backboardMaterial.diffuseTexture = this.backboardTexture;
		this.backboardMaterial.diffuseColor = new Color3(0.64,0.64,0.64);
		this.backboardMaterial.specularColor = new Color3(0.1,0.1,0.1);
		this.backboardContext = this.backboardTexture.getContext();

		


		this.checksBeforeReady++;
		this.defaultBackboardImage.onload = () => {
			this.backboardContext.drawImage(this.defaultBackboardImage, 0, 0);
			this.backboardTexture.update();
			checkLoadDone();
		}
		this.checksBeforeReady++;
		this.goalBackboardImage.onload = ()=> { checkLoadDone();};
		this.goalBackboardImage.src = "./assets/3d-models/Images/backboard-goal.jpg?nocache=" + moment().valueOf();


		this.defaultBackboardImage.src = this.backboardFiles[0].src;
		this.backboardImages.push(this.defaultBackboardImage)
		if(this.backboardFiles.length > 1) {
			for(let i = 1; i < this.backboardFiles.length; i++) {
				let imageData = this.backboardFiles[i];
				let shouldShow = true;
				if(imageData.fromDate && moment().isBefore(moment(imageData.fromDate, "YYYY-MM-DD"))) {
					shouldShow = false;
				}
				if(imageData.toDate && moment().isAfter(moment(imageData.toDate, "YYYY-MM-DD"))) {
					shouldShow = false;
				}
				if(shouldShow) {
					let image = new Image();
					this.checksBeforeReady++;
					image.onload = () => { 
						checkLoadDone();
					}
					image.src = this.backboardFiles[i].src;	
					this.backboardImages.push(image);
				}
			}
		}
	
			
		// start timeout
		setInterval( () => {
			// only cycle the banner when the goal image isn't showing
			if (!this.goalImageShowing) {
				this.currentBackboardImageIndex++;
				if (this.currentBackboardImageIndex >= this.backboardImages.length) this.currentBackboardImageIndex = 0;
				// set background music
				this.backboardChangeImage(this.backboardImages[this.currentBackboardImageIndex]);
			}
		}, 15000);

		// this.buttonShineTexture = new Texture("./assets/3d-models/Images/button-shine.png?nocache=" + moment().valueOf(), this.scene);
		// this.buttonShineTexture.hasAlpha = true;
		// this.buttonShineTexture.uOffset = 1;
		// this.buttonShineTexture.vOffset = 1;

		this.soundOnImage = new Texture("./assets/3d-models/Images/pinball-button-soundon.jpg?nocache=" + moment().valueOf(), this.scene);
		this.soundOffImage = new Texture("./assets/3d-models/Images/pinball-button-soundoff.jpg?nocache=" + moment().valueOf(), this.scene);
		
		this.ledboardTexture = new Texture('./assets/3d-models/Images/winter-pinball-led-small.png', this.scene);
		this.ledboardMaterial = new StandardMaterial('ledboardMaterial', this.scene);
		this.ledboardMaterial.diffuseTexture = this.ledboardTexture;
		
		this.goalNetTexture = new Texture('./assets/3d-models/Images/goal-net.png', this.scene);
		this.goalNetTexture.hasAlpha = true;
		this.goalNetMaterial = new StandardMaterial('goalNetMaterial', this.scene);
		this.goalNetMaterial.diffuseColor = new Color3(1,1,1);
		this.goalNetMaterial.diffuseTexture = this.goalNetTexture;
		this.goalNetMaterial.useAlphaFromDiffuseTexture = true
		if(this.CONSOLE_DEBUGGING) this.win.goalNetMaterial = this.goalNetMaterial;
		
		this.roundShadowTexture = new Texture("./assets/3d-models/Images/winter-pinball-shadow-circle.png?nocache=" + moment().valueOf(), this.scene);
		this.roundShadowTexture.hasAlpha = true;
		this.roundShadowTexture.coordinatesMode = 4;
		this.roundShadowTexture.invertZ = true;
		
		
		this.squareShadowTexture = new Texture("./assets/3d-models/Images/winter-pinball-shadow-square.png?nocache=" + moment().valueOf(), this.scene);
		this.squareShadowTexture.hasAlpha = true;
		this.squareShadowTexture.coordinatesMode = 4;
		this.roundShadowTexture.invertZ = true;


		if(this.CONSOLE_DEBUGGING) {
		} else { // conflicts with console positioning so only add if CONSOLE_DEBUGGING = off
		}
		
		this.checksBeforeReady++;
		SceneLoader.ImportMesh(null, "./assets/3d-models/", "fullcase-optimised-v2.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			var centerpos = new Vector3(0,0,0);
			var pinBallCase = new TransformNode("pinballCase", this.scene);
			// pinBallCase.setParent(this.pinballMachine);
			for(let mesh of meshes) {
				mesh.scaling.x = -1;
				mesh.setParent(pinBallCase);
				mesh.position = centerpos;
				mesh.visibility = this.GRAPHIC_MODELS_VISIBILITY;
				if(mesh.material.name == 'Inner_wall_logo') {
					mesh.material = this.ledboardMaterial;
				} else if(mesh.material.name == 'Top_texture') {
					// @ts-ignore
					window.material = mesh.material;
					// @ts-ignore
					mesh.material.diffuseTexture.uOffset = 50
					// @ts-ignore
					window.mesh = mesh;
				} else if(mesh.material.name == 'Backboard') {
					mesh.material = this.backboardMaterial;
				} else if(mesh.material.name == 'grassmat') {
					mesh.receiveShadows = true;
					mesh.material.freeze();
				} else {
					mesh.material.freeze();
				}
				mesh.alphaIndex = 0;
				this.meshesToUnIndex.push(mesh);
				this.meshesToFreezeWorldMatrix.push(mesh);
			}
			
			checkLoadDone();
		});
		
		this.checksBeforeReady++;
		SceneLoader.ImportMesh(null, "./assets/3d-models/", "Kado.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			this.kado = meshes[0];
			this.kado.name = "kado";
			this.kado.scaling.x = this.kado.scaling.y = this.kado.scaling.z = (this.isPhoneDevice() ? 1.5 : 1.2) * 9;
			
			this.kado.position.copyFrom(this.kado_position);
			this.kado.position.y = 300;
			
			this.kado.rotation.y = 1.2 * Math.PI;
			this.kado.physicsImpostor = new PhysicsImpostor(this.kado, PhysicsImpostor.BoxImpostor, {mass:0, restitution: this.OBSTACLE_RESTITUTION, friction:this.OBSTACLE_FRICTION}, this.scene);
			
			this.kado.setParent(this.pinballMachine);
			this.kado.material.freeze();

			let shadowplane = this.createShadowPlane('kado_shadow', 10, 10);
			shadowplane.position.copyFrom(this.kado_position);
			shadowplane.position.y = 0.09999;
			shadowplane.position.z += -0.5
			shadowplane.position.x += -0.5
			shadowplane.rotation.z = Math.PI*1.325
			shadowplane.scaling = new Vector3(0.1, 0.1, 0.1);
			shadowplane.visibility = 0;

			this.fakeShadows.set('kado', [shadowplane]);

			// @ts-ignore
			window.kadoshadow = shadowplane;
			shadowplane.setParent(this.pinballMachine);

			checkLoadDone();
		});
		
		this.checksBeforeReady++;
		SceneLoader.ImportMesh(null, "./assets/3d-models/", "Sled.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			this.sled = meshes[0];
			this.sled.name = "sled";
			this.sled.scaling.x = this.sled.scaling.y = this.sled.scaling.z = 40;
			this.sled.material.backFaceCulling = false;
			this.sled.position.copyFrom(this.sled_position);
			this.sled.position.y = 300;
			this.sled.rotation.y = 1.2 * Math.PI;
			this.sled.physicsImpostor = new PhysicsImpostor(this.sled, PhysicsImpostor.BoxImpostor, {mass:0, restitution: this.OBSTACLE_RESTITUTION, friction:this.OBSTACLE_FRICTION}, this.scene);
			
			// this.sled.receiveShadows = true;
			this.sled.setParent(this.pinballMachine);
			this.sled.material.freeze();
			
			let shadowplane = this.createShadowPlane('sled_shadow', 2.5, 20);
			shadowplane.position.x = 17;
			shadowplane.position.z = 30;
			shadowplane.position.y = 0.09999;
			shadowplane.rotation.z = Math.PI * 1.3;
			shadowplane.scaling = new Vector3(0.1, 0.1, 0.1);
			shadowplane.visibility = 0;
			shadowplane.setParent(this.pinballMachine);


			let shadowplane2 = this.createShadowPlane('sled_shadow', 2.5, 20);
			shadowplane2.position.x = 14;
			shadowplane2.position.z = 25.25;
			shadowplane2.position.y = 0.09999;
			shadowplane2.rotation.z = Math.PI * 1.3;
			shadowplane2.scaling = new Vector3(0.1, 0.1, 0.1);
			shadowplane2.visibility = 0;
			shadowplane2.setParent(this.pinballMachine);

			
			let shadowplane3 = this.createShadowPlane('sled_shadow', 9, 17);
			shadowplane3.position.x = 16
			shadowplane3.position.z = 27
			shadowplane3.position.y = 0.09999;
			shadowplane3.rotation.z = Math.PI * 1.3;
			shadowplane3.scaling = new Vector3(0.1, 0.1, 0.1);
			shadowplane3.visibility = 0;
			shadowplane3.setParent(this.pinballMachine);

			this.fakeShadows.set('sled', [shadowplane, shadowplane2, shadowplane3]);


			// @ts-ignore
			window.sled = this.sled;
			// @ts-ignore
			window.sledshadow = shadowplane;
			// @ts-ignore
			window.sledshadow2 = shadowplane2;
			// @ts-ignore
			window.sledshadow3 = shadowplane3;
			checkLoadDone();
		});					
		
		this.checksBeforeReady++;
		SceneLoader.ImportMesh(null, "./assets/3d-models/", "christmastree.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			this.tree = meshes[0];
			this.tree.name = "tree";
			for(let i = 1; i < meshes.length; i++) {
				meshes[i].setParent(this.tree);
			}
			this.tree.scaling.x = this.tree.scaling.y = this.tree.scaling.z = (this.isPhoneDevice() ? 1.5 : 1.2) * 1.5;
			this.tree.position.copyFrom(this.tree_position);
			this.tree.position.y = 300;
			this.tree.rotation.y = 1.6;
			this.tree.physicsImpostor = new PhysicsImpostor(this.tree, PhysicsImpostor.BoxImpostor, {mass:0, restitution: this.OBSTACLE_RESTITUTION, friction:this.OBSTACLE_FRICTION}, this.scene);
			
			this.tree.setParent(this.pinballMachine);
			this.tree.material.freeze();
			
			let shadowplane = this.createShadowPlane('tree_shadow', 18, 18, true);
			shadowplane.position.copyFrom(this.tree_position);
			shadowplane.position.y = 0.09999;
			shadowplane.scaling = new Vector3(0.1, 0.1, 0.1);
			shadowplane.visibility = 0;
			shadowplane.setParent(this.pinballMachine);

			this.fakeShadows.set('tree', [shadowplane]);

			// @ts-ignore
			window.tree = this.tree;
			// @ts-ignore
			window.treeshadow = shadowplane;
			checkLoadDone();
		});		
		
		this.checksBeforeReady++;
		SceneLoader.ImportMesh(null, "./assets/3d-models/", "Snowman.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			this.snowman = meshes[1]; // the body
			this.snowman.name = "snowman";
			for(let i = 0; i < meshes.length; i++) {
				if(i != 1) meshes[i].setParent(this.snowman);
			}
			this.snowman.scaling.x = this.snowman.scaling.y = this.snowman.scaling.z = 0.031;
			this.snowman.physicsImpostor = new PhysicsImpostor(this.snowman, PhysicsImpostor.CylinderImpostor, {mass:0, restitution: this.OBSTACLE_RESTITUTION, friction:this.OBSTACLE_FRICTION}, this.scene);
			this.snowman.position.copyFrom(this.snowman_position);
			this.snowman.position.y = 300;
			this.snowman.rotation.y = 0.6;
			this.snowman.setParent(this.pinballMachine);
			this.snowman.material.freeze();
			
			let shadowplane = this.createShadowPlane('snowman_shadow', 15, 15, true);
			shadowplane.position.copyFrom(this.snowman_position);
			shadowplane.position.y = 0.09999;
			shadowplane.scaling = new Vector3(0.1, 0.1, 0.1);
			shadowplane.visibility = 0;
			
			shadowplane.setParent(this.pinballMachine);
			this.fakeShadows.set('snowman', [shadowplane]);

			// @ts-ignore
			window.snowman = this.snowman;
			// @ts-ignore
			window.snowmanshadow = shadowplane;
			checkLoadDone();
		});
		
		this.checksBeforeReady++;
		SceneLoader.ImportMesh(null, "./assets/3d-models/", "soccerball_low.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			this.soccerBall.setEnabled(false);
			for(let mesh of meshes) {
				mesh.setParent(this.soccerBall);
			}
			checkLoadDone();
		});
		
		this.checksBeforeReady++;
		SceneLoader.ImportMesh(null, "./assets/3d-models/", "plunger.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			this.plunger = meshes[0];
			meshes[1].setParent(this.plunger);
			this.plunger.position = new Vector3(this.plungerMax.x, this.plungerMax.y, this.plungerMax.z);
			this.plunger.physicsImpostor = new PhysicsImpostor(this.plunger, PhysicsImpostor.BoxImpostor, {mass:0, restitution: 0, friction: this.WALL_FRICTION}, this.scene);
			
			this.plunger.setParent(this.pinballMachine);
			// this.plunger.isPickable = false;
			this.meshesToUnIndex.push(this.plunger);
			checkLoadDone();
		});
		
		this.checksBeforeReady++;
		SceneLoader.ImportMesh(null, "./assets/3d-models/", "right-button.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			this.rightButton = new TransformNode("rightButton", this.scene);
			meshes[0].setParent(this.rightButton);
			meshes[1].setParent(this.rightButton);
			this.rightButtonMeshes = meshes;
			for( let mesh of this.rightButtonMeshes) {
				mesh.name = "rightButton_mesh_" + this.rightButtonMeshes.indexOf(mesh);
				mesh.isPickable = true;
			}
			this.rightButton.position = new Vector3(-18, 3.25, 49);
			this.rightButton.setParent(this.pinballMachine);
			this.meshesToUnIndex.push(this.rightButton);
			this.meshesToFreezeWorldMatrix.push(this.rightButton);
			checkLoadDone();
		});
		
		this.checksBeforeReady++;
		SceneLoader.ImportMesh(null, "./assets/3d-models/", "left-button.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			this.leftButton = new TransformNode("leftButton", this.scene);
			meshes[0].setParent(this.leftButton);
			meshes[1].setParent(this.leftButton);
			this.leftButtonMeshes = meshes;
			for( let mesh of this.leftButtonMeshes) {
				mesh.name = "leftButton_mesh_" + this.leftButtonMeshes.indexOf(mesh);
				mesh.isPickable = true;
			}
			this.leftButton.position = new Vector3(26, 3.25, 49);
			this.leftButton.setParent(this.pinballMachine);
			this.meshesToUnIndex.push(this.leftButton);
			this.meshesToFreezeWorldMatrix.push(this.leftButton);
			checkLoadDone();
		});
		
		this.checksBeforeReady++;
		SceneLoader.ImportMesh(null, "./assets/3d-models/", "left-button-small.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			this.smallButton = new TransformNode("smallButton", this.scene);
			meshes[0].setParent(this.smallButton);
			meshes[1].setParent(this.smallButton);
			this.smallButtonMeshes = meshes;
			for( let mesh of this.smallButtonMeshes) {
				mesh.name = "smallButton_mesh_" + this.smallButtonMeshes.indexOf(mesh);
				mesh.isPickable = true;
			}
			this.smallButton.position = new Vector3(17.5, 3.25, 49);
			this.smallButton.setParent(this.pinballMachine);
			this.meshesToUnIndex.push(this.smallButton);
			this.meshesToFreezeWorldMatrix.push(this.smallButton);
			checkLoadDone();
		});
		
		this.checksBeforeReady++;
		SceneLoader.ImportMesh("", "./assets/3d-models/", "Arch.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			// SceneLoader.ImportMesh("", "./assets/3d-models/", "Soccer-Goal.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			this.goal = new TransformNode("goal", this.scene);
			
			for(let mesh of meshes) {
				mesh.setParent(this.goal);
			}
			var posy = 0.25;
			this.goal.setParent(this.pinballMachine);
			this.goal.position = new Vector3(0,posy,-41);
			this.goal.scaling.x = this.goal.scaling.z = this.goal.scaling.y = 10;
			// @ts-ignore
			window.goal =	this.goal;
			// goal.rotationQuaternion = Quaternion.RotationAxis(yAxis, 0);
			let shadowplane = this.createShadowPlane('arch_R_shadow', 9, 7);
			shadowplane.position = new Vector3(-10.75 ,0.09999, -40.5);
			let dummyArch = this.createObstacle(this.scene, shadowplane.position.clone(), 6, 10, 6);
			dummyArch.position.y = 5;
			dummyArch.position.z = -42.5;
			
			// @ts-ignore
			window.archshadow1 = shadowplane;
			
			let shadowplane2 = this.createShadowPlane('arch_L_shadow', 9, 7);
			shadowplane2.position = new Vector3(11.5 ,0.09999, -40);
			let dummyArch2 = this.createObstacle(this.scene, shadowplane2.position.clone(), 6, 10, 6);
			dummyArch2.position.y = 5;
			dummyArch2.position.z = -42.5;

			// @ts-ignore
			window.archshadow2 = shadowplane2;
			// @ts-ignore
			window.archLeft = dummyArch2;
			// @ts-ignore
			window.archRight = dummyArch;

			shadowplane.setParent(this.pinballMachine);
			shadowplane2.setParent(this.pinballMachine);
			this.goalmeshes = meshes;
			
			if(this.CONSOLE_DEBUGGING) {
				this.win.goal = this.goal;
			}
			checkLoadDone();
		});

		this.checksBeforeReady++;
		SceneLoader.ImportMesh(null, "./assets/3d-models/", "chevron-light.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			this.chevronLight = meshes[0];
			this.chevronLight.setParent(this.pinballMachine);
			let startPos = this.BALL_START_POSITION.z + 1.5;
			this.chevronLight.position = new Vector3(-27, 3.2, startPos);
			this.chevronLight.name = "chevronLight_0";
			this.chevronLights.push(this.chevronLight);
			this.meshesToUnIndex.push(this.chevronLight);
			this.meshesToFreezeWorldMatrix.push(this.chevronLight);
			let triggerPoint = this.BALL_START_POSITION.clone();
			triggerPoint.z = this.chevronLight.position.z + 0.5;
			triggerPoint.y = 1;
			this.chevronTriggers.push(triggerPoint);
			for(let i = 0; i < 11; i++) {
				var index = i + 1;
				let light = this.chevronLight.clone("chevronLight_"+index, this.pinballMachine);
				light.material = light.material.clone("chevronLightMat_"+index);
				light.position.z = startPos - index * 5;
				this.chevronLights.push(light);
				this.meshesToUnIndex.push(light);
				this.meshesToFreezeWorldMatrix.push(light);
				let triggerPoint = this.BALL_START_POSITION.clone();
				triggerPoint.z = light.position.z + 0.5;
				triggerPoint.y = 1 ;
				this.chevronTriggers.push(triggerPoint);
			}
			
			checkLoadDone();
		});

		this.checksBeforeReady += 3;// 1 mesh and 2 flippers to wait for
		SceneLoader.ImportMesh(null, "./assets/3d-models/", "case-physics.obj?nocache=" + moment().valueOf(), this.scene, (meshes) => {
			var posy = 3;
			
			this.casePhysics = meshes[0];
			this.casePhysics.visibility = this.PHYSICS_MODELS_VISIBILITY;
			
			this.casePhysics.position = new Vector3(0,0,0);
			this.casePhysics.isPickable = false;
			this.casePhysics.physicsImpostor = new PhysicsImpostor(this.casePhysics, PhysicsImpostor.MeshImpostor, {mass:0, restitution:this.BOUNDS_RESTITUTION, friction: this.WALL_FRICTION}, this.scene);
			
			this.casePhysics.setParent(this.pinballMachine);
			
			this.meshesToUnIndex.push(this.casePhysics);
			this.meshesToFreezeWorldMatrix.push(this.casePhysics);
			checkLoadDone();
			
			// (async() => {
			let functionalFlipper = async (right: boolean) => {
				let fac = right ? 1 : -1;
				let flip = await createFlipper("FF" + (right ? "R" : "L"), this.scene, right, new Vector3(-9.5 * fac, 0.0, 50), this.machineAngle, this.casePhysics.physicsImpostor, this.flipperSound);
				flip.graphic.isPickable = false;
				this.onRenderList.push(() => flip.update());
				this.shadowMeshes.push(flip.graphic);
				// var win:any = window;
				if(right) {
					this.rightFlipper = flip;				
					// this.win.right = rightFlipper;
				} else {
					this.leftFlipper = flip;
					// this.win.left = leftFlipper;
				}
				
				if (this.FLIPPER_AUTOFIRE) {
					// auto fire
					setTimeout(() => {
						setInterval(() => {
							flip.setPowered(true);
							setTimeout(() => {
								flip.setPowered(false);
							}, 500);
						}, 1000);
					}, right ? 0 : 500);
				}
				checkLoadDone();
			}
			functionalFlipper(true);
			functionalFlipper(false);

			// })();
		});



		
		let texturesToLoad = [this.ledboardTexture, this.grassTexture, this.fieldLinesTexture, this.goalNetTexture, this.ballTexture, this.goalieTexture, this.backboardTexture, this.soundOffImage, this.soundOnImage, this.skyboxTexture];
		let texturesLoaded = 0;
		for(let texture of texturesToLoad) {
			if(texture && texture.isReady) {
				texturesLoaded++;
			}
		}
		this.checksBeforeReady++;
		if(texturesLoaded >= texturesToLoad.length) {
			checkLoadDone();
		} else {
			Texture.WhenAllReady(texturesToLoad, () => {
				checkLoadDone();
			});
		}
	}
	
	backboardChangeImage(image:any, onDone:()=>void = null) {
		this.backboardAnimating = true;
		this.currentBackboardImage = image;
		requestAnimationFrame(() => { this.animateBackboard(onDone); });
	}

	changeSmallButtonImage(image:any) {
		this.currentSmallButtonImage = image;
		requestAnimationFrame(() => { 
			// @ts-ignore
			this.smallButtonMeshes[0].material.diffuseTexture = image;
		});
	}
	
	backboardGoBackToDefault() {
		this.backboardChangeImage(this.backboardImages[this.currentBackboardImageIndex], ()=> {
			this.goalImageShowing = false;
		});
	}
	
	animateBackboard(onDone:()=>void = null) {
		this.backboardContext.globalAlpha = this.backboardOpacity;
		this.backboardContext.drawImage(this.currentBackboardImage, 0, 0);
		this.backboardOpacity += 0.01;
		this.backboardTexture.update();
		if (this.backboardOpacity < 1) {
			requestAnimationFrame(() => { this.animateBackboard(onDone); });
		}
		else {
			this.backboardOpacity = 0;
			this.backboardAnimating = false;
			if(onDone) onDone();
		}
	}

	setCollisionHandlers() {
		if(this.collisionObjects && this.collisionObjects.length > 0 && this.pinBall) {
			for(let obstacle of this.collisionObjects) {
				this.pinBall.physicsImpostor.registerOnPhysicsCollide(obstacle.object.physicsImpostor, () => {
					if(obstacle.cooldown != 0) {
						var now = moment().valueOf();
						if(now > obstacle.lastTrigger + obstacle.cooldown) {
							obstacle.lastTrigger = now;
						} else {
							return;
						}
					}
					var points = obstacle.points;
					var func = obstacle.func;
					var isGoal = obstacle.isGoal;
					var sound = obstacle.sound;
					
					if(sound) sound.play();
					this.scoreAnimation(points, obstacle.object.position, obstacle.object);
					
					this.score += points;
					this.scoreElement.innerHTML = "" + this.score;
					if (typeof func == "function") {
						func(obstacle);
					}
				});
			}
		}
	}
	
	checkObstacleCollisions() {
		if(this.collisionObjects && this.collisionObjects.length > 0 && this.pinBall) {
			var ballX =  this.pinBall.position.x;
			var ballZ = this.pinBall.position.z;
			if(ballX < -30) return;
			
			if(ballX < 0 && ballZ < -10) {
				this.checkObstacleCollision(this.collisionObjects[3], this.pinBall);
				this.checkObstacleCollision(this.collisionObjects[4], this.pinBall);
			} else if(ballX > 0 && ballZ < -10) {
				this.checkObstacleCollision(this.collisionObjects[2], this.pinBall);
				this.checkObstacleCollision(this.collisionObjects[4], this.pinBall);
			} else if(ballX > 0 && ballZ > -10) {
				this.checkObstacleCollision(this.collisionObjects[1], this.pinBall);
			} else {
				this.checkObstacleCollision(this.collisionObjects[0], this.pinBall);
			}
		}
	}
	
	checkObstacleCollision(obstacle:any, pinBall:Mesh) {
		if(this.physicsEngine._isImpostorPairInContact(obstacle.object.physicsImpostor, pinBall.physicsImpostor)) {
			if(obstacle.cooldown != 0) {
				var now = moment().valueOf();
				if(now > obstacle.lastTrigger + obstacle.cooldown) {
					obstacle.lastTrigger = now;
				} else {
					return;
				}
			}
			var points = obstacle.points;
			var func = obstacle.func;
			var isGoal = obstacle.isGoal;
			var sound = obstacle.sound;
			
			if(sound) sound.play();
			this.scoreAnimation(points, obstacle.object.position, obstacle.object);
			
			this.score += points;
			this.scoreElement.innerHTML = "" + this.score;
			if (typeof func == "function") {
				func(obstacle);
			}
		}
	}
	
	createEventListeners() {
		window.onresize = () => {
			this.updateSceneSize();
		};
		// window.addEventListener("blur", pauseGame);
		// window.addEventListener("focus", unpauseGame);
		this.canvas.onresize = () => {
			this.updateSceneSize();
		};
		
		if(this.md.is('ios')) { // because crapple
			this.canvas.ontouchstart=(ev) => this.handleTouchStart(ev);
			this.canvas.ontouchmove=(ev) => this.handleTouchMove(ev);
			this.canvas.ontouchend=(ev) => this.handleTouchEnd(ev);
		} else  { // because chrome
			this.canvas.onpointerup=(ev) => this.handleTouchEnd(ev);
			this.canvas.onpointermove=(ev) => this.handleTouchMove(ev);
			this.canvas.onpointerdown=(ev) => this.handleTouchStart(ev);
		}
		
		this.canvas.onmousedown = (ev) => this.handleTouchStart(ev);
		this.canvas.onmouseup =	(ev) => this.handleTouchEnd(ev);
		
		this.canvas.onkeydown = (ev) => this.onKeyDown(ev);
		
		this.canvas.onkeyup = (ev) => this.onKeyUp(ev);
		
		window.onscroll = (e:any) => {
			if(this.cameraControlsActive) return;
			e.preventDefault();
		};
		document.body.onscroll = (e:any) => {
			if(this.cameraControlsActive) return;
			e.preventDefault();
		};
		
		// this.canvas.addEventListener('touchstart', (e) => {
		// 	// console.log('touchstart', e);
		// 	if(e.changedTouches[0].clientX < window.innerWidth/2) {
		// 		// e.preventDefault();
		// 		this.leftIsPowered = true;
		// 	} else if(e.changedTouches[0].clientX > window.innerWidth/2 && e.changedTouches[0].clientX < window.innerWidth*0.9) {
		// 		// e.preventDefault();
		// 		this.rightIsPowered = true;
		// 	}
		//
		// 	Engine.audioEngine.audioContext.resume();
		// 	Engine.audioEngine.unlock();
		// }, {passive: true});
		
		// this.canvas.addEventListener('touchend', (e) => {
		// 	// console.log('touchend', e);
		// 	if(e.changedTouches[0].clientX < window.innerWidth/2) {
		// 		// e.preventDefault();
		// 		this.leftIsPowered = false;
		// 	} else if(e.changedTouches[0].clientX > window.innerWidth/2 && e.changedTouches[0].clientX < window.innerWidth*0.9) {
		// 		// e.preventDefault();
		// 		this.rightIsPowered = false;
		// 	}
		//
		// 	Engine.audioEngine.audioContext.resume();
		// 	Engine.audioEngine.unlock();
		// }, {passive: true});
		
		
		this.canvas.onclick= (e) => {
			Engine.audioEngine.audioContext.resume();
			Engine.audioEngine.unlock();
		};
	}
	
	handleTouchStart(e:any) {
		if(this.cameraControlsActive) return;
		let mouseLocation = this.getEventLocation(e);
		let eventId = this.getEventId(e);
		var pickInfo = this.scene.pick(this.scene.pointerX, this.scene.pointerY, (mesh) => {
			if(!mesh.isPickable) return false
			if (mesh == this.leftButtonMeshes[0] || mesh == this.leftButtonMeshes[1] || mesh == this.smallButtonMeshes[0] || mesh == this.smallButtonMeshes[1] || mesh == this.rightButtonMeshes[0] || mesh == this.rightButtonMeshes[1]) {
				return true;
			}
			return false;
		});
		if (pickInfo.hit && !this.draggingPlunger) {
			if (pickInfo.pickedMesh == this.rightButtonMeshes[0] || pickInfo.pickedMesh == this.rightButtonMeshes[1]) {
				// this.arrowIsDown = true;
				this.launchBall();
			} else if (pickInfo.pickedMesh == this.leftButtonMeshes[0] || pickInfo.pickedMesh == this.leftButtonMeshes[1]) {
				this.openUrlFromSettings();
			} else if(pickInfo.pickedMesh == this.smallButtonMeshes[0] || pickInfo.pickedMesh == this.smallButtonMeshes[1]) {
				this.toggleSound();
			}
			
		} else {
			if(this.pinBall && this.pinBall.position.x < -30 && this.pinBall.position.z > 0) {
				if(this.draggingId == null) {
					this.draggingPlunger = true;
					this.draggingId = eventId;
					this.dragStart = mouseLocation.y;
					this.dragDistance = 0;
				}
			} else {
				if(mouseLocation.x < window.innerWidth/2) {
					this.leftIsPowered = true;
					if(eventId != null) this.leftUpId.push(eventId);
				} else if(mouseLocation.x > window.innerWidth/2) {
					this.rightIsPowered = true;
					if(eventId != null) this.rightUpId.push(eventId);
				}
			}
		}
	}
	
	getEventLocation(e:any) {
		if(e.type.indexOf('touch') > -1) {
			let ev = e.changedTouches && e.changedTouches[e.changedTouches.length -1] ? e.changedTouches[e.changedTouches.length -1] : null;
			if(ev) {
				return {
					x: ev.clientX,
					y: ev.clientY
				}
			}
		} else {
			return {
				x: e.clientX,
				y: e.clientY
			}
		}
		return null;
	}
	
	getEventId(e:any) {
		if(e.type.indexOf('touch') > -1) {
			return e.changedTouches && e.changedTouches[e.changedTouches.length -1] ? e.changedTouches[e.changedTouches.length -1].identifier : null;
		} else {
			return e.pointerId ? e.pointerId : null;
		}
		return null;
	}
	
	handleTouchMove(e:any) {
		if(this.cameraControlsActive) return;
		let mouseLocation = this.getEventLocation(e);
		let eventId = this.getEventId(e);
		if(this.draggingId == eventId) {
			this.dragDistance = (this.dragStart- mouseLocation.y) / 10;
		}
	}
	
	handleTouchEnd(e:any) {
		if(this.cameraControlsActive) return;
		let eventId = this.getEventId(e);
		
		var pickInfo = this.scene.pick(this.scene.pointerX, this.scene.pointerY, (mesh) => {
			if(!mesh.isPickable) return false
			if (mesh == this.leftButtonMeshes[0] || mesh == this.leftButtonMeshes[1] || mesh == this.smallButtonMeshes[0] || mesh == this.smallButtonMeshes[1] || mesh == this.rightButtonMeshes[0] || mesh == this.rightButtonMeshes[1]) {
				return true;
			}
			return false;
		});
		if (!pickInfo.hit) {
			if(this.leftIsPowered) {
				// e.preventDefault();
				if(eventId != null && this.leftUpId.indexOf(eventId)  > -1) {
					this.leftIsPowered = false;
					this.leftUpId.splice(this.leftUpId.indexOf(eventId), 1)
				} else {
					this.leftIsPowered = false;
				}
			} else if(this.rightIsPowered) {
				// e.preventDefault();
				if(eventId != null && this.rightUpId.indexOf(eventId)  > -1) {
					this.rightIsPowered = false;
					this.rightUpId.splice(this.rightUpId.indexOf(eventId), 1)
				} else {
					this.rightIsPowered = false;
				}
			}
			
		}
		if(this.draggingId == eventId) {
			this.draggingId = null;
			this.draggingPlunger = false;
		}
	}
	
	onKeyDown(event:any) {
		if(this.cameraControlsActive) return;
		switch (event.code) {
			case "ArrowDown":
			case "Space":
			event.preventDefault();
			this.arrowIsDown = true;
			break;
			case "ArrowLeft":
			case "KeyA":
			event.preventDefault();
			this.leftIsPowered = true;
			break;
			case "ArrowRight":
			case "KeyD":
			event.preventDefault();
			this.rightIsPowered = true;
			break;
		}
		Engine.audioEngine.unlock();
	}
	
	onKeyUp(event:any) {
		if(this.cameraControlsActive) return;
		switch (event.code) {
			case "ArrowDown":
			case "Space":
			event.preventDefault();
			this.arrowIsDown = false;
			break;
			case "ArrowLeft":
			case "KeyA":
			event.preventDefault();
			this.leftIsPowered = false;
			break;
			case "ArrowRight":
			case "KeyD":
			event.preventDefault();
			this.rightIsPowered = false;
			break;
		}
		Engine.audioEngine.unlock();
	}
	
	getConfig() {
		var rawFile = new XMLHttpRequest();
		rawFile.open("GET", 'config.json?nocache=' + moment().valueOf(), false);
		var validUrl = /((https?|ftp|file):\/\/)?[-A-Za-z0-9+&@#\/%?=~_|!:,.;]+[-A-Za-z0-9+&@#\/%=~_|]/;
		rawFile.onreadystatechange = () =>
		{
			if(rawFile.readyState === 4)
			{
				if(rawFile.status === 200 || rawFile.status == 0)
				{
					var allText = rawFile.responseText;
					let obj = JSON.parse(allText)
					if(validUrl.test(obj.redirect_url)) {
						this.redirectUrl = obj.redirect_url;
						this.apiUrl = obj.api_url;
						this.gameId = obj.game_id;
						return;
					}
				}
			}
		}
		rawFile.send(null);
	}

	openUrlFromSettings()
	{
		var rawFile = new XMLHttpRequest();
		rawFile.open("GET", 'config.json?nocache=' + moment().valueOf(), false);
		var validUrl = /((https?|ftp|file):\/\/)?[-A-Za-z0-9+&@#\/%?=~_|!:,.;]+[-A-Za-z0-9+&@#\/%=~_|]/;
		rawFile.onreadystatechange = () =>
		{
			if(rawFile.readyState === 4)
			{
				if(rawFile.status === 200 || rawFile.status == 0)
				{
					var allText = rawFile.responseText;
					let obj = JSON.parse(allText)
					if(validUrl.test(obj.redirect_url)) {
						window.open(obj.redirect_url);
						return;
					}
				}
			}
			window.open('https://www.smartvertizing.nl/'); // fallback
		}
		rawFile.send(null);
	}
	
	launchBall() {
		this.quicklaunch = true;
		this.quicklaunchTime = moment().valueOf() + 250;
	}
	
	rotateMachine() {
		this.pinballMachine.rotationQuaternion = Quaternion.RotationAxis(new Vector3(1,0,0), this.machineAngle);
	}	
	
	pauseGame() {
		this.gamePaused = true;
		try {
			if(this.playingMucic && this.playingMucic.isPlaying) this.playingMucic.stop();
		} catch(e) {
			console.log(e);
		}
	}
	
	unpauseGame() {
		this.lastSubloopRun = 0;
		var now = moment().valueOf();
		if(this.collisionObjects) {
			for(let obj of this.collisionObjects) {
				obj.lastTrigger = now;
			}
		}
		this.leftIsPowered = false;
		this.rightIsPowered = false;
		this.gamePaused = false;
		this.engine.clear(this.CLEAR_COLOR, true, true);
		if(this.playingMucic) this.playingMucic.play();
		setTimeout(() => {  this.canvas.focus(); }, 100);
	}
	
	setCameraPosition(x:number, y:number, z:number) {
		this.camera.position.x = x;
		this.camera.position.y = y;
		this.camera.position.z = z;
		this.camera.lockedTarget = this.WORLD_ORIGIN.clone();
	}
	
	resetCamera() {
		this.setPresetCameraPos(this.isPhoneDevice() ? 9 : this.currentCameraPreset);
	}
	
	// buttonShine() {
	// 	if(this.buttonShineTexture && this.leftButtonShineMaterial) {
	// 		this.nextShine = moment().valueOf() + Math.random() * 3000 + 2000;
	// 		let shineAnim = new Animation("shineAnim", "vOffset", 60, Animation.ANIMATIONTYPE_FLOAT);
	// 		let shineAnim2 = new Animation("shineAnim", "uOffset", 60, Animation.ANIMATIONTYPE_FLOAT);
	// 		let topKeys = [
	// 			{
	// 				frame:0,
	// 				value: 1.433
	// 			},
	// 			{
	// 				frame: 60,
	// 				value: 2.433
	// 			}
	// 		];
			
	// 		let alphaAnim = new Animation("alphaAnim", "alpha", 120, Animation.ANIMATIONTYPE_FLOAT);
	// 		let alphaKeys = [
	// 			{
	// 				frame:0,
	// 				value: 0
	// 			},
	// 			{
	// 				frame: 60,
	// 				value: 1
	// 			},
	// 			{
	// 				frame: 120,
	// 				value: 0
	// 			}
	// 		];
	// 		shineAnim.setKeys(topKeys);
	// 		shineAnim2.setKeys(topKeys);
	// 		alphaAnim.setKeys(alphaKeys);
	// 		this.scene.beginDirectAnimation(this.buttonShineTexture, [shineAnim, shineAnim2], 0, 60, false, 3, () => {
	// 			this.scene.removeAnimation(shineAnim);
	// 			this.scene.removeAnimation(shineAnim2);
	// 		});
	// 		this.scene.beginDirectAnimation(this.leftButtonShineMaterial, [alphaAnim], 0, 120, false, 3, () => {
	// 			this.scene.removeAnimation(alphaAnim);
	// 		});
	// 	}
	// }
	
	animateCamera() {
		if(this.camera) {
			let cameraPreset = window.innerWidth < 768 ? 9 : this.currentCameraPreset;
			let presetSettings = this.getPresetCameraSettings(cameraPreset);
			this.camera.position = new Vector3(-120, 35, 50);
			this.camera.lockedTarget = this.WORLD_ORIGIN.clone();
			let flyByAnimPos = new Animation("flyByAnimPos", "position", 240, Animation.ANIMATIONTYPE_VECTOR3, 1);
			let flyByAnimTargetPos = new Animation("flyByAnimTargetPos", "lockedTarget", 240, Animation.ANIMATIONTYPE_VECTOR3, 1);
			let flyByAnimPosKeys = [
				{
					frame: 0,
					value: this.camera.position
				},
				{
					frame: 120,
					value: new Vector3(-75, 31.25, 110)
				},
				{
					
					frame: 240,
					value: presetSettings.position.clone()
				}
			];
			let flyByAnimTargetPosKeys = [
				{
					frame: 0,
					value: this.camera.lockedTarget
				},
				{
					
					frame: 240,
					value: presetSettings.target.clone()
				}
			];
			flyByAnimPos.setKeys(flyByAnimPosKeys);
			flyByAnimTargetPos.setKeys(flyByAnimTargetPosKeys);
			if(this.loadingScreen){ 
				this.loadingScreen.style.opacity = "0";
			}
			this.scene.beginDirectAnimation(this.camera, [flyByAnimPos, flyByAnimTargetPos], 0, 240, false, 0.2, () => {
				this.scene.removeAnimation(flyByAnimPos);
				this.setPresetCameraPos(cameraPreset);
				this.animateDropObstacles();
				// animateCamera();
			});
		}
	}
	
	getRandomGoalie() {
		var index = Math.floor(Math.random() * this.goalies.length);
		return this.goalies[index];
	}
	
	isMobileDevice() {
		return this.md.mobile() != null && (this.md.phone() != null || this.md.tablet() != null);
	}
	getDevice() {
		return this.md.tablet() ? "tablet" : this.md.phone() ? "phone" : 'desktop';
	}
	
	isPhoneDevice() {
		var bounds = this.gameContainer.getBoundingClientRect()
		return this.md.mobile() != null && this.md.phone() != null || window.innerWidth/window.innerHeight < 1;
	}

	isSafari15_4() {
		return navigator.userAgent.indexOf('Version/15.4') >= 0 && navigator.userAgent.indexOf('Safari/');
	}
	
	toggleCameraControls() {
		setTimeout(() => {
			this.cameraControlsActive = this.cameraToggle.classList.contains('active');
			if(this.cameraControlsActive) {
				this.cameraInputs.classList.remove('hidden');
				// console.log('attachControl');
				this.camera.attachControl(this.canvas);
			} else {
				this.camera.detachControl(this.canvas);
				// console.log('detachControl');
				this.cameraInputs.classList.add('hidden');
			}
		}, 300);
	}
	
	getGoaliePlanePosition() {
		let pos = this.goalieObstaclePlanePosition.clone();
		pos.x -= 0.5
		return pos;
	}
	
	optimizeShadowResolution(scene:Scene, optimizer:SceneOptimizer):boolean {
		if(this.engine.getFps() < this.minshadowfps) {
			if(this.currentShadowResolution > this.minShadowResolution) {
				let newResolution = this.currentShadowResolution / 2;
				if(newResolution >= this.minShadowResolution) {
					this.setShadowMapResolution(newResolution);
				}
				return this.engine.getFps() >= this.minshadowfps;
			}
		}
		return true;
	}
	
	rescale(right: boolean) {
		let target = this.canvasScale + (right ? 1 : -1);
		let scaleSteps = this.CANVAS_SCALE_STEPS;
		if (target >= 0 && target < scaleSteps.length) {
			this.canvasScale = target;
			this.updateSceneSize();
		}
	}

	createShadowPlane(name:string, width:number = 1, height:number = 1, isRound:boolean = false) {
		let plane = MeshBuilder.CreatePlane(name, {height:height, width:width}, this.scene);
		plane.isPickable = false;
		plane.visibility = 1;
		plane.alphaIndex = 5;
		plane.rotation.y = Math.PI;
		plane.rotation.x = Math.PI *0.5;
		let material = new StandardMaterial(`${name}_material`, this.scene);
		material.diffuseColor = new Color3(1,1,1);
		material.diffuseTexture = isRound ? this.roundShadowTexture : this.squareShadowTexture;
		material.useAlphaFromDiffuseTexture = true;
		plane.material = material;
		return plane;
	}
	
	// Watch for browser/canvas resize events
	updateSceneSize() {
		// logical / css width
		// TODO check mobile (DPI)
		var bounds = this.gameContainer.getBoundingClientRect()
		let ratio = window.innerWidth/ window.innerHeight;
		let lW = window.innerWidth;
		let lH = this.isPhoneDevice() ? window.innerHeight : window.innerHeight;
		
		// pixel resolution
		let s = this.CANVAS_SCALE_STEPS[this.canvasScale];
		if(this.isPhoneDevice()) s *=  devicePixelRatio;
		var pxW = window.innerWidth * s;
		var pxH = this.isPhoneDevice() ? window.innerHeight * s : window.innerHeight * s;
		// limit to 1080
		let maxWidth = 	!this.isPhoneDevice() ? 1920 : 1920 * window.devicePixelRatio;
		let maxHeight = !this.isPhoneDevice() ? 1080 : 1080 * window.devicePixelRatio;
		if(ratio < 1) {
			maxWidth = 	!this.isPhoneDevice() ? 1080 : 1080 * window.devicePixelRatio;
			maxHeight = !this.isPhoneDevice() ? 1920 : 1920 * window.devicePixelRatio;
		}
		
		if (pxW > maxWidth) {
			pxH = Math.floor(pxH * maxWidth / pxW);
			pxW = maxWidth;
		} else if (pxH > maxHeight) {
			pxW = Math.floor(pxW * maxHeight / pxH);
			pxH = maxHeight;
		}
		
		// then fill the screen using CSS
		if((pxW != this.prevRenderWidth || pxH != this.prevRenderHeight)) {
			this.canvas.style.width = `${lW}px`;
			this.canvas.style.height = `${lH}px`;
			this.scene.render();
			
			this.engine.setSize(pxW, pxH);
			this.engine.setHardwareScalingLevel(1/s);
			if(this.goaliePlane) this.goaliePlane.position = this.getGoaliePlanePosition();
			this.scene.render();
			this.prevRenderWidth = pxW;
			this.prevRenderHeight = pxH;
			// canvas.focus();
			this.updateCamera();
		}
	}
	
	animateDropObstacles() {
		let obstacles = [this.goaliePlane, this.kado, this.tree, this.snowman, this.sled];
		let obstaclesPositions = [this.getGoaliePlanePosition(), this.kado_position, this.tree_position, this.snowman_position, this.sled_position];
		let animations:any[] = [];
		for(let i = 0; i < obstacles.length; i++) {
			let obstacle = obstacles[i];
			let anim = new Animation(obstacle.name + 'Anim', "position", 100, Animation.ANIMATIONTYPE_VECTOR3);
			let keys = [{
				frame:0,
				value: obstacle.position
			}, {
				frame: 100,
				value: obstaclesPositions[i]
			}]
			anim.setKeys(keys);
			animations.push({obstacle: obstacle, anim:anim});
		}
		let runAnim = () => {
			if(animations.length > 0) {
				let item = animations.shift();
				this.scene.beginDirectAnimation(item.obstacle, [item.anim], 0, 100, false, 2, () => {
					runAnim();
					this.scene.removeAnimation(item.anim);
				});
				this.animateShadowFadeIn(item.obstacle.name)
			} else {
				this.onIntroAnimationDone();
			}
		};
		runAnim();
	}

	animateShadowFadeIn(key:string, delay:number = 0) {
		let shadows:Mesh[] = this.fakeShadows.get(key);
		if(typeof shadows == "undefined") return;		
		let scaleAnim = new Animation(`${key}_scale_shadowAnim`, 'scaling', 100, Animation.ANIMATIONTYPE_VECTOR3);
		let scaleKeys = [{
			frame: 0,
			value: new Vector3(0,0,0)
		},{
			frame: 50,
			value: new Vector3(0,0,0)
		},		
		{
			frame:100,
			value: new Vector3(1,1,1)
		}];
		scaleAnim.setKeys(scaleKeys);

		let alphaAnim = new Animation(`${key}_alpha_shadowAnim`, 'visibility', 100, Animation.ANIMATIONTYPE_FLOAT);
		let alphaKeys = [{
			frame: 0,
			value: 0
		},{
			frame: 50,
			value: 0
		},		
		{
			frame:100,
			value: 1
		}]
		alphaAnim.setKeys(alphaKeys);
		
		for(let shadow of shadows) {
			this.scene.beginDirectAnimation(shadow, [scaleAnim, alphaAnim], 0, 100, false, 2);
		}		
	}

	preventScrollActions(e:any) {
		if(this.cameraControlsActive) return;
		e.preventDefault();
	}
	
	destroyGame() {
		try {
			for(let sound of this.sounds) {
				if(sound.isPlaying) sound.stop();
			}
			
			this.scene.dispose();
		} catch(e) {
			console.log(e);
		}
		this.resetScore();
		$("#game-main").addClass('hidden');
		
		window.onresize = (ev:any) => {};
		this.canvas.onresize = (ev:any) => {};
		this.canvas.ontouchstart = (ev:any) => {};
		this.canvas.ontouchmove = (ev:any) => {};
		this.canvas.ontouchend = (ev:any) => {};
		this.canvas.onpointerup = (ev:any) => {};
		this.canvas.onpointermove = (ev:any) => {};
		this.canvas.onpointerdown = (ev:any) => {};
		this.canvas.onmousedown = (ev:any) => {};
		this.canvas.onmouseup = (ev:any) => {};
		this.canvas.onkeydown = (ev:any) => {};
		this.canvas.onkeyup = (ev:any) => {};
		window.onscroll = (ev:any) => {};
		document.body.onscroll = (ev:any) => {};
		this.canvas.onclick = (ev:any) => {};
	}
}