
import * as BABYLON from "babylonjs";
import { getMats } from "./mats";
import { FLIPPER_FRICTION } from ".";

const HIDE_ALL_DEBUG = true;
const SHOW_LIMITERS = true && !HIDE_ALL_DEBUG;
const SHOW_FLIPBOX = true && !HIDE_ALL_DEBUG;
const SHOW_MARKERS = true && !HIDE_ALL_DEBUG;

const COMPOUND_FLIPPER = true;
const DISABLE_JOINT = false;
const SLOWDOWN = 1;

type StopStrategy = "pins" | "angle";
const STOP_STRATEGY: StopStrategy = "angle";

const LIMITER_EXTRA = false;
const LIMITER_PLATES = false;

const FLIPPER_RANGE_ANGLE = Math.PI * 0.3;
const MOTOR_FORCE = 18;

export interface Flipper {
    update(): void;
    setPowered(p: boolean): void;
    graphic: BABYLON.AbstractMesh;
}

export function createFlipper(name: string, scene: BABYLON.Scene, right: boolean, position: BABYLON.Vector3, angle: number, anchor: BABYLON.PhysicsImpostor, sound:BABYLON.Sound): Promise<Flipper> {

    let mats = getMats(scene);
    let mirrorFac = right ? 1 : -1;
    let mirrorVec = new BABYLON.Vector3(mirrorFac, 1, 1);

    return new Promise((resolve, reject) => {
        BABYLON.SceneLoader.ImportMesh("", "./assets/3d-models/", COMPOUND_FLIPPER ? "flipper-optimised.obj" : "flipper-physics.obj", scene, function(meshes) {

            try {

                const localToWorldDir = (l: BABYLON.Vector3) => {
                    return BABYLON.Vector3.TransformCoordinates(l, anchor.object.getWorldMatrix())
                        .subtractInPlace(BABYLON.Vector3.TransformCoordinates(BABYLON.Vector3.Zero(), anchor.object.getWorldMatrix()));
                }
                const localToWorld = (l: BABYLON.Vector3) => {
                    return BABYLON.Vector3.TransformCoordinates(l.add(position), anchor.object.getWorldMatrix());
                }

                let flip: BABYLON.AbstractMesh;
                let flipMass = DISABLE_JOINT ? 0 : 10;
                let graphic: BABYLON.AbstractMesh;

                let limiterY = 14 + (right ? 7 : 0);
                let limiterHeight = 5;
                let limiterVis = SHOW_LIMITERS ? 0.5 : 0;

                let flipperHeight = 3;
                let halfRange = FLIPPER_RANGE_ANGLE/2;

                if (COMPOUND_FLIPPER) {
                    flip = new BABYLON.Mesh("", scene);

                    graphic = meshes[0];
                    graphic.parent = flip;

                    // TODO
                    let box1 = BABYLON.MeshBuilder.CreateBox("", {width: 2, height: flipperHeight, depth: 10.5}, scene);
                    box1.position = new BABYLON.Vector3(0, flipperHeight/2, -3.2);
                    box1.parent = flip;
                    box1.material = mats.testRed;
                    box1.visibility = SHOW_FLIPBOX ? 0.5 : 0;
                    box1.physicsImpostor = new BABYLON.PhysicsImpostor(box1, BABYLON.PhysicsImpostor.BoxImpostor, {mass:0, friction: FLIPPER_FRICTION}, scene);
                    // physView.showImpostor(box1.physicsImpostor);

                    if (STOP_STRATEGY == "pins") {
                        let limiter = BABYLON.MeshBuilder.CreateBox("", {width: 2, height: limiterHeight, depth: 18}, scene);
                        limiter.position = new BABYLON.Vector3(0, limiterY, 0);
                        limiter.parent = flip;
                        limiter.material = mats.testBlue;
                        limiter.visibility = limiterVis;
                        limiter.physicsImpostor = new BABYLON.PhysicsImpostor(limiter, BABYLON.PhysicsImpostor.BoxImpostor, {mass:0}, scene);
                    }

                    if (SHOW_MARKERS) {
                        let len = BABYLON.MeshBuilder.CreateBox("", {width: 0.2, height: 0.2, depth: 30}, scene);
                        len.position = new BABYLON.Vector3(0, 0, 0);
                        len.parent = flip;
                        len.material = mats.testBlue;
                    }

                    flip.physicsImpostor = new BABYLON.PhysicsImpostor(flip, BABYLON.PhysicsImpostor.NoImpostor, {mass: flipMass, restitution:0}, scene);

                } else {
                    flip = graphic = meshes[0];
                    flip.physicsImpostor = new BABYLON.PhysicsImpostor(flip, BABYLON.PhysicsImpostor.BoxImpostor, {mass: flipMass, restitution:0}, scene);
                    // physView.showImpostor(flip.physicsImpostor);
                }

                const forcePosition = (relativeAngle: number) => {
                    flip.position = localToWorld(BABYLON.Vector3.Zero());
                    flip.rotationQuaternion = BABYLON.Quaternion.Identity();
                    flip.rotate(BABYLON.Vector3.Left(), -angle);
                    flip.rotate(BABYLON.Vector3.Up(), (-Math.PI*0.5 - (halfRange*relativeAngle) ) * mirrorFac);
                }

                // set the initial position, or random stuff will happen. later, the joint takes care of positioning.
                forcePosition(1);

                if (SHOW_MARKERS) {
                    // debug graphic: pivot
                    let pivBox = BABYLON.MeshBuilder.CreateBox("", {width: 0.2, height: 8, depth: 0.2}, scene);
                    pivBox.material = mats.testRed;
                    pivBox.position = localToWorld(new BABYLON.Vector3(0, 4, 0));
                    pivBox.rotate(BABYLON.Vector3.Left(), -angle);

                    // debug graphics: back and forward
                    [1, -1].forEach(i => {

                        let pointCont = new BABYLON.TransformNode("");
                        pointCont.position = localToWorld(new BABYLON.Vector3(0, 0, 0));
                        pointCont.rotate(BABYLON.Vector3.Left(), -angle);
                        pointCont.rotate(BABYLON.Vector3.Up(), halfRange * i);

                        let pointBox = BABYLON.MeshBuilder.CreateBox("", {width: 0.2, height: 4, depth: 0.2, }, scene);
                        pointBox.material = mats.testGreen;
                        pointBox.position = new BABYLON.Vector3(9*mirrorFac, 0, 0);
                        pointBox.parent = pointCont;

                    });
                }

                let joint: BABYLON.MotorEnabledJoint | null = null;
                if (!DISABLE_JOINT) {
                    joint = new BABYLON.MotorEnabledJoint(BABYLON.PhysicsJoint.HingeJoint, {
                        mainPivot: position.clone(),
                        mainAxis: new BABYLON.Vector3(0, 1, 0),
                        connectedPivot: new BABYLON.Vector3(0, 0, 0),
                        connectedAxis: new BABYLON.Vector3(0, 1, 0)
                    });
                    anchor.addJoint(flip.physicsImpostor, joint);
                }

                let motorForce = MOTOR_FORCE * SLOWDOWN * mirrorFac;
                let dir = -1; // -1 is forwards
                let motoring = false;
                let powerBlocked = 0;

                let update: () => void;

                const stop = () => {
                    motoring = false;
                    if (joint) joint.setMotor(0);
                    flip.physicsImpostor.setAngularVelocity(BABYLON.Vector3.Zero());
                }

                if (STOP_STRATEGY == "pins") {

                    let stopDia = 5;

                    const createStop = (x: number, z: number) => {
                        let stop = BABYLON.MeshBuilder.CreateCylinder("", {diameter: stopDia, height: limiterHeight + 1}, scene);
                        stop.physicsImpostor = new BABYLON.PhysicsImpostor(stop, BABYLON.PhysicsImpostor.CylinderImpostor, {mass:0}, scene);
                        stop.visibility = limiterVis;
                        // physView.showImpostor(stop.physicsImpostor);
                        stop.position = localToWorld(new BABYLON.Vector3(x, limiterY, z).multiplyInPlace(mirrorVec));
                        stop.rotate(BABYLON.Vector3.Left(), -angle);
                        return stop;
                    }
                    let stopX = 4;
                    let stopZ = 7;
                    let backStop = createStop(stopX, stopZ);
                    let fwdStop = createStop(stopX, -stopZ);
                    if (LIMITER_EXTRA) {
                        setTimeout(() => {
                            let fwdStopExtra = createStop(-stopX, stopZ*1.1);
                            let backStopExtra = createStop(-stopX, -stopZ*1.1);
                        }, 4000);
                    }

                    if (LIMITER_PLATES) {
                        const createPlate = (f: number) => {
                            let stop = BABYLON.MeshBuilder.CreateBox("", {width: 13, height: 3, depth: 13}, scene);
                            stop.physicsImpostor = new BABYLON.PhysicsImpostor(stop, BABYLON.PhysicsImpostor.BoxImpostor, {mass:0}, scene);
                            stop.visibility = limiterVis;
                            // physView.showImpostor(stop.physicsImpostor);
                            stop.position = localToWorld(new BABYLON.Vector3(0, limiterY + ((limiterHeight/2) + (3/2) + 0.1)*f, 0).multiplyInPlace(mirrorVec));
                            stop.rotate(BABYLON.Vector3.Left(), -angle);
                        }
                        createPlate(1);
                        createPlate(-1);
                    }

                    flip.physicsImpostor.registerOnPhysicsCollide(backStop.physicsImpostor, function(collider:any, collidedAgainst:any) {
                        if (dir == 1) {
                            stop();
                            // powerBlocked = 1;
                        }
                    });
                    flip.physicsImpostor.registerOnPhysicsCollide(fwdStop.physicsImpostor, function(collider:any, collidedAgainst:any) {
                        if (dir == -1) {
                            stop();
                            // powerBlocked = -1;
                        }
                    });

                    update = () => {};

                } else if (STOP_STRATEGY == "angle") {

                    // points to the middle of the flipper pair
                    let worldUp = localToWorldDir(BABYLON.Vector3.Up()).normalize();
                    let worldNeutral = localToWorldDir(BABYLON.Vector3.Right().multiplyInPlace(mirrorVec)).normalize();

                    let outOfRangeCount = 0;

                    update = () => {
                        // follows the length of the flipper in world space (where it "points" to)
                        let worldFlipperOut = BABYLON.Vector3.TransformCoordinates(BABYLON.Vector3.Backward(), flip.getWorldMatrix())
                            .subtractInPlace(flip.position).normalize();

                        // angle between flipper and the neutral position, positive is backwards
                        let curAngle = BABYLON.Vector3.GetAngleBetweenVectors(worldFlipperOut, worldNeutral, worldUp) * mirrorFac;

						// stop the flipper if it's at the limit
                        if (motoring && Math.abs(curAngle) > halfRange && Math.sign(curAngle) == dir) {
                            if (motoring) {
	                            stop();
	                            // set exactly on the limit, because it will overshoot
	                            forcePosition(dir);
                                powerBlocked = dir;
	                        }
                        }

                        // clear the power block if near neutral
                        if (Math.abs(curAngle) < halfRange * 0.5) {
                            powerBlocked = 0;
                        }

                        // if for any reason the angle gets way outside the valid range for a time, reset it
                        if (Math.abs(curAngle) > halfRange * 1.5 && ++outOfRangeCount > 60) {
                            //console.log(`RESET FLIPPER ${name} BECAUSE ANGLE ${curAngle} IS WAY OUTSIDE RANGE ${halfRange}`);
                            forcePosition(1);
                            outOfRangeCount = 0;
                        }
                    }

                }

                const setPowered = (p: boolean) => {
                    let nd = p ? -1 : 1;
					//console.log(powerBlocked, nd, powerBlocked == nd);
                    if (powerBlocked == nd) return;
                    if(p && dir != nd && sound) sound.play();
                    dir = nd;
                    motoring = true;
                    if (joint) joint.setMotor(motorForce * dir);
                }
                setPowered(false);

                resolve({
                    update,
                    setPowered,
                    graphic
                });

            } catch (e) {
                reject(e);
            }

        });
    });

}
