import * as BABYLON from "babylonjs";

export function getMats(scene: BABYLON.Scene) {
    let testGreen = new BABYLON.StandardMaterial("", scene);
    testGreen.diffuseColor = new BABYLON.Color3(0, 1, 0.5);
    testGreen.specularColor = new BABYLON.Color3(0.1,0.1,0.1);
    testGreen.freeze();

    let testRed = new BABYLON.StandardMaterial("", scene);
    testRed.diffuseColor = new BABYLON.Color3(1, 0, 0);
    testRed.specularColor = new BABYLON.Color3(0.1,0.1,0.1);
    testRed.freeze();

    let testBlue = new BABYLON.StandardMaterial("", scene);
    testBlue.diffuseColor = new BABYLON.Color3(0, 0, 1);
    testBlue.specularColor = new BABYLON.Color3(0.1,0.1,0.1);
    testBlue.freeze();

    return {
        testGreen, testRed, testBlue
    };

}
